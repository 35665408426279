/* tslint:disable:no-magic-numbers */

import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *    Translates YYYY-MM-DD to MM-DD-YYYY
 *
 **/
@Pipe({
    name: 'dateYearSwapper',
})
export class DateYearSwapperPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }

        if (value.indexOf('T') >= 0) {
            value = value.slice(0, 10);
        }
        const stringArray = value.split('-');

        return [stringArray[1], stringArray[2], stringArray[0]].join('-');
    }
}
