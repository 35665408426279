import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { nameConcat } from '../../..//shared/utilities/NameConcat';
import { ConfigService } from '../../../shared/config.service';
import { first, flatten, second } from '../../../shared/utilities/Array';
import { DropdownConfig, DropdownOption, DropdownOptionValue } from '../shared/components/defaulted-dropdown/dropdownConfig.types';
import { MemberDetails } from '../shared/services/member.service.types';
import { MemberPCP } from './../search/provider-search.model';
import {
    MedicalBenefitsComponentConfig,
    Member,
    MemberCoverage,
    Name,
    PlanBenefit,
    PlanType,
    Policies,
    PolicyResult,
    ProductCoverage,
} from './benefits.types';
import { CoveragePeriod, Plan, PlanDetail, PrimaryCareProvider } from './medical/medical.types';

/** FHPI-3575, FHPI-3646 */
const relationshipToSubscriberMapping = {
    '01': 'Spouse',
    18: 'Subscriber',
    19: 'Child',
};

/**
 * Big ol policies and benefits service. This will eventually be broken into smaller services
 */
@Injectable({
    providedIn: 'root',
})
export class PolicyAndBenefitsService {
    memberDetails: MemberDetails; // Members API
    policies: Policies; // Policies API
    currentPolicy: number;
    benefitsConfig: MedicalBenefitsComponentConfig;

    constructor(private http: HttpClient, private configService: ConfigService) {
        this.benefitsConfig = this.configService.getPageConfig('benefits');
    }

    getPlans(planType: PlanType): Observable<Plan[]> {
        const api_implementation: string = this.configService.getPageConfig('global')['api_implementation'] || 'default';

        const params: { [key: string]: string } = {};

        return this.http
            .get<Policies>('/api/enrollment/policies/', { params })
            .pipe(
                map(
                    (policies: Policies) => this.toPlans(policies, api_implementation, planType),
                    (error: unknown) => console.error(error),
                ),
            );
    }
    toPlans(policies: Policies, api_implementation: string, planType: PlanType): Plan[] {
        const productCoverages: ProductCoverage[] = this.toProductCoverages(policies).filter(
            (productCoverage: ProductCoverage) => this.transformProductCoverageType(productCoverage) === planType,
        );
        const plans: Plan[] = api_implementation !== 'default' ? this.v2Plans(productCoverages) : this.defaultPlans(productCoverages);

        return plans;
    }
    defaultPlans(productCoverages: ProductCoverage[]): Plan[] {
        return productCoverages.map((productCoverage: ProductCoverage) => ({
            name: productCoverage.plan_information.name,
            coveragePeriod: productCoverage.termination_date
                ? this.toCoveragePeriod(productCoverage.effective_date, productCoverage.termination_date)
                : this.toCoveragePeriod(productCoverage.effective_date),
            effectiveDate: new Date(productCoverage.effective_date),
            details: this.toPlanDetails(productCoverage),
        }));
    }
    v2Plans(productCoverages: ProductCoverage[]): Plan[] {
        return productCoverages.map((productCoverage: ProductCoverage) => ({
            name: productCoverage.external_plan_name,
            coveragePeriod: productCoverage.termination_date
                ? this.toCoveragePeriod(productCoverage.effective_date, productCoverage.termination_date)
                : this.toCoveragePeriod(productCoverage.effective_date),
            effectiveDate: new Date(productCoverage.effective_date),
            memberCoverages: productCoverage.member_coverages,
            details: [],
        }));
    }

    setCoveragePeriod(coverages: ProductCoverage[] | MemberCoverage[]): void {
        for (const coverage of coverages) {
            coverage.coveragePeriod = coverage.termination_date
                ? this.toCoveragePeriod(coverage.effective_date, coverage.termination_date)
                : this.toCoveragePeriod(coverage.effective_date);
        }
    }

    isActive(input: DropdownOptionValue | ProductCoverage | MemberCoverage | MemberPCP): boolean {
        const dateNow: number = new Date().getTime();

        return (
            !input.termination_date ||
            (dateNow >= new Date(input.effective_date).getTime() && dateNow <= new Date(input.termination_date).getTime())
        );
    }

    // Benefit Period spec has start date and end date so added check for start date and enddate
    isBenefitPeriodActive(input: DropdownOptionValue) {
        const dateNow: number = new Date().getTime();

        return (
            (dateNow >= new Date(input.start_date).getTime() && dateNow <= new Date(input.end_date).getTime()) ||
            (dateNow >= new Date(input.start_date).getTime() && !input.end_date)
        );
    }

    isBenefitPeriodPreActive(input: DropdownOptionValue) {
        const dateNow: number = new Date().getTime();

        return dateNow < new Date(input.start_date).getTime();
    }

    setMemberPlanStatus(coveragePeriod: CoveragePeriod, member: Member): void {
        const dateNow = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        const firstCoverage = first(coveragePeriod).getTime();
        const secondCoverage = second(coveragePeriod) ? second(coveragePeriod).getTime() : undefined;
        const isPreActive: boolean = dateNow < firstCoverage;
        const isActive: boolean = !secondCoverage || (dateNow >= firstCoverage && dateNow <= secondCoverage);
        const isInactive: boolean = secondCoverage && dateNow > secondCoverage;

        if (isPreActive) {
            member.planStatus = 'pre-active';

            return;
        }
        if (isActive) {
            member.planStatus = 'active';
        }
        if (isInactive) {
            member.planStatus = 'inactive';
        }
    }

    formattedCoveragePeriod(coveragePeriod: CoveragePeriod): string {
        const noTerminationDateText = this.benefitsConfig?.noTerminationDateText || '';

        if (!second(coveragePeriod)) {
            return `${this.MMddyyyy(first(coveragePeriod))} - ${noTerminationDateText}`;
        }

        return `${this.MMddyyyy(first(coveragePeriod))} - ${this.MMddyyyy(second(coveragePeriod))}`;
    }

    formatMemberProviderNames(members: Member[]): void {
        for (const member of members) {
            if (member.name) {
                member['formatted_name'] = this.formattedName(member.name);
            }
            if (member.pcp_list) {
                this.formatPrimaryCareProviderNames(member.pcp_list);
            }
        }
    }

    formatPrimaryCareProviderNames(pcpList: PrimaryCareProvider[]): void {
        for (const primaryCareProvider of pcpList) {
            if (primaryCareProvider.provider && primaryCareProvider.provider.name) {
                primaryCareProvider.provider['formatted_name'] = this.formattedName(primaryCareProvider.provider.name);
            }
        }
    }

    setOptionInActivePeriod(periodDropdownConfig: DropdownConfig): DropdownOption {
        return periodDropdownConfig.options.find((option: DropdownOption) => {
            const optionValue: DropdownOptionValue = option.value;

            return this.isActive(optionValue);
        });
    }

    formattedName(name: Name): string {
        return nameConcat(name);
    }

    MMddyyyy(date: Date): string {
        const formattedDate = `${this.twoDigitFormat(date.getUTCMonth() + 1)}/${this.twoDigitFormat(
            date.getUTCDate(),
        )}/${date.getUTCFullYear()}`;

        return formattedDate;
    }

    /** Make date and month 2 digits */
    twoDigitFormat(input: string | number): string {
        const lastTwoChars = -2;

        return `0${input}`.slice(lastTwoChars);
    }

    patchValueByActivePeriod(
        dropDownOptionInActivePeriod: DropdownOption,
        dropdownForm: FormGroup,
        periodDropdownConfig: DropdownConfig,
    ): DropdownOptionValue {
        if (dropDownOptionInActivePeriod) {
            dropdownForm.controls.coverage_period.patchValue(dropDownOptionInActivePeriod.value);

            return dropDownOptionInActivePeriod.value;
        } else if (periodDropdownConfig?.options[0]?.value) {
            const firstPeriodOptionValue: DropdownOptionValue = periodDropdownConfig.options[0].value;
            dropdownForm.controls.coverage_period.patchValue(firstPeriodOptionValue);

            return firstPeriodOptionValue;
        }
    }

    setOptionValuesByProductCoverageName(dropdownConfig: DropdownConfig, productCoverageName: string): DropdownOptionValue {
        const selectedOption = dropdownConfig.options.find(
            (option: DropdownOption) => option.value.product_coverage_name === productCoverageName,
        );

        return selectedOption.value;
    }

    toCoveragePeriod(effectiveDate: string, terminationDate?: string): CoveragePeriod {
        // When date doesn't have time it assumes date in UTC  and converts to local time 
        const start = effectiveDate.includes(':') ? new Date(effectiveDate) : new Date(`${effectiveDate}T00:00:00`);
        let end: Date;
        if (terminationDate) {
            end = terminationDate.includes(':') ? new Date(terminationDate) : new Date(`${terminationDate}T00:00:00`);
        }
        else {
            end = null
        }
        return [start, end];
    }

    toPlanDetails(productCoverage: ProductCoverage): PlanDetail[] {
        return productCoverage.plan_information.plan_benefits.map((planBenefit: PlanBenefit) => ({
            benefitName: planBenefit.benefit_category_display_text,
            inNetworkBenefitValue: planBenefit.co_payment,
            outOfNetworkBenefitValue: planBenefit.out_of_pocket,
        }));
    }
    toProductCoverages(policies: Policies): ProductCoverage[] {
        return flatten(policies.results.map((policyResult: PolicyResult) => policyResult.product_coverages));
    }

    toDefaultCoverage(productCoverages: ProductCoverage[], defaultCoverageType?: string): ProductCoverage {
        const default_coverage_type: string = defaultCoverageType ? defaultCoverageType : 'medical';

        return productCoverages.find((policy: ProductCoverage) => {
            const policy_type: string = this.transformProductCoverageType(policy);

            return policy_type === default_coverage_type;
        });
    }

    // eslint-disable-next-line @typescript-eslint/typedef
    transformProductCoverageType = (productCoverage: ProductCoverage): string => {
        if (typeof productCoverage.product_coverage_type === 'string') {
            return productCoverage.product_coverage_type.toLowerCase();
        } else if (productCoverage.product_coverage_type_display) {
            return productCoverage.product_coverage_type_display.toLowerCase();
        }
    };

    transformRelationshipToSubscriber(relationshipToSubscriber: string): string {
        const relationship: string = relationshipToSubscriber?.toString();
        const matchedKey: string = Object.keys(relationshipToSubscriberMapping).find((key: string) => key === relationship);
        if (matchedKey) return relationshipToSubscriberMapping[matchedKey];

        return relationshipToSubscriber;
    }
}
