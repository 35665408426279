import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { map, filter, catchError, tap } from 'rxjs/operators';
import { pipe, UnaryFunction, EMPTY, BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigService } from '../../../../shared/config.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { Url } from '../../../../shared/types/url.type';
import { first, last, none } from '../../../../shared/utilities/Array';
import { exists } from '../../../../shared/utilities/Exists';
import { nameConcat } from '../../../../shared/utilities/NameConcat';
import { stringBuilder } from '../../../../shared/zipui-shared-module/string-builder';
import { AccountConfig } from '../../account/account.model';
import { Member } from '../../benefits/benefits.types';
import { PolicyAndBenefitsService } from '../../benefits/policy-and-benefits.service';
import { Claim } from '../../claims/claim.interface';
import { ApiListResponse } from '../models/api-list-response.model';
import { MemberPolicy } from '../models/member-policy-api-response.model';
import { isNonNull } from '../../../../shared/utilities/IsNonNull';
import { PaginatorParams } from '../components/paginator/paginator.service';
import { CurrentPolicyService, noPolicyIsCurrentlySelected } from './../../../../shared/services/current-policy.service';
import { ZipFormControlService } from './../../../../shared/zipui-shared-module/zip-form-control/zip-form-control.service';
import { MemberCoverage, ProductCoverage } from './../../benefits/benefits.types';
import { DropdownConfig, DropdownOption } from './../components/defaulted-dropdown/dropdownConfig.types';
import { Filter, LabelValue } from './../types/filters';
import {
    Address,
    AllMemberQueryParams,
    AllMembers,
    BenefitsConfig,
    ClaimsConfig,
    MemberBenefitPeriods,
    MemberBenefits,
    MemberDetails,
    MemberName,
} from './member.service.types';
import { Member as MemberModel } from './../../../../api/models/member';
import {
    MobileFilterConfig,
    FilterGroup,
} from './../../../../shared/provider-shared-module/zip-filter-group/zip-filter-group-mobile-wrapper.component';

export type ExternalSearchEntities = 'provider' | 'pharmacy';
export type NetworkCode = string;

/** UCare's HQ. Used for the frequent case when member detail addresses can't be used to prepopulate provider or pharmacy searches. */
const defaultAddress: Partial<Address> = {
    zip_code: '55413',
    lat: 44.9956,
    lng: -93.2581,
};

const defaultNetworkCode: NetworkCode = 'RERE20';

/**
 * Member service to handle critical data requests and state
 */
@Injectable()
export class MemberService {
    public memberDetails$: BehaviorSubject<MemberDetails> = new BehaviorSubject<MemberDetails>(new MemberDetails());
    memberCount$: BehaviorSubject<number> = new BehaviorSubject(0);
    public member_details: MemberDetails;
    currentPolicy: number;
    public memberId: any;
    public policy: MemberPolicy;
    public policies: MemberPolicy[];
    private claims: Claim[] = [];

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private logger: LoggerService,
        private policyAndBenefitsService: PolicyAndBenefitsService,
        private currentPolicyService: CurrentPolicyService,
        private fcService: ZipFormControlService,
    ) {
        this.initEnrollmentPolicies(); // See method docstring.
    }

    /**
     * We are letting components use the enrollment policies response synchronously. There is no way for the member to change their
     * enrollment policy in the course of using the application. We only need to fetch this data once when the user logs in.
     */
    initEnrollmentPolicies(): void {
        const params = {};
        if (this.currentPolicyService.currentPolicy$.value.id !== noPolicyIsCurrentlySelected) {
            params['policy_id3'] = `${this.currentPolicyService.currentPolicy$.value.id}`;
        }
    }

    /**
     * Don't call this in the constructor. I don't know why but it was removed already.
     * We needed a method to initialize the member service from other components.
     */
    construct(): void {
        if (this.memberId) {
            this.initMemberData();
        }
    }

    formattedName(name: MemberName): string {
        return nameConcat(name);
    }

    public getProductCoverage(productCoverageType: string = 'medical'): ProductCoverage {
        const pcType = productCoverageType ? productCoverageType.toLowerCase() : 'medical';
        let productCoverage = this.policy?.product_coverages.find(
            (product_coverage: ProductCoverage) => product_coverage?.product_coverage_type?.toLowerCase() === pcType,
        );
        if (!productCoverage) productCoverage = this.policy.product_coverages[0];

        return productCoverage;
    }

    public getProductCoverages(productCoverageType: string = 'medical'): ProductCoverage[] {
        if (this.policy) {
            return this.policy.product_coverages.filter(
                (product_coverage: ProductCoverage) =>
                    product_coverage?.product_coverage_type?.toLowerCase() === productCoverageType.toLowerCase(),
            );
        }
    }

    public getDistinctProductCoverageTypes(): string[] {
        if (this.policy) {
            return Array.from(
                new Set(
                    this.policy.product_coverages.map((pc) =>
                        pc?.product_coverage_type?.toLowerCase() === 'drug' ? 'pharmacy' : pc?.product_coverage_type?.toLowerCase(),
                    ),
                ),
            );
        }
    }

    public getMemberCoveragesByProduct(productCoverageType?: string): MemberCoverage[] {
        const pcType = productCoverageType ? productCoverageType.toLowerCase() : 'medical';

        return this.getProductCoverage(pcType).member_coverages;
    }

    /** use api to populate member drop down*/
    public getMemberFilters(filters: any): any {
        this.http.get('/api/member-portal/members').subscribe(
            (response: ApiListResponse<Member>) => {
                const uniqueNames: Set<string> = new Set<string>();
                const members: Member[] = response.results;
                members.forEach((member: Member) => uniqueNames.add(member?.full_name ? member.full_name : nameConcat(member.name)));
                this.populateMemberFilters(filters, members, uniqueNames);
            },
            (error: unknown) => {
                this.logger.consoleError(error);
                filters['areFiltersReady'] = true;
            },
            () => {
                filters['areFiltersReady'] = true;
            },
        );
    }

    // Iterate through all member coverages and add unique names and values to the member name filter
    populateMemberFilters(filters: Filter[], members: Member[], uniqueNames: Set<string>): any {
        filters.map((filter: Filter) => {
            // don't touch filters unless flaged to add member values
            if (filter.memberValueKey) {
                const defaultOption = filter?.options?.[0];
                const mapMember = members
                    .map((member: Member) => {
                        // pull required label and value from member object
                        let optionLabel = member[filter.memberDisplayKey];
                        let optionValue = member[filter.memberValueKey];
                        // name is an object > change for display
                        if (filter.memberDisplayKey === 'name') optionLabel = nameConcat(optionLabel);
                        if (filter.exactValue) optionValue = optionLabel;
                        if (uniqueNames.has(optionLabel) || uniqueNames.has(optionValue)) {
                            uniqueNames.delete(optionLabel);
                            uniqueNames.delete(optionValue);

                            return { label: optionLabel, value: optionValue };
                        }
                    })
                    .filter((memberNameOption: DropdownOption) => !!memberNameOption);
                filter.options = defaultOption ? [defaultOption, ...mapMember] : [...mapMember];

                // Assign new value to the subject, in order to trigger the option changes
                this.fcService.emit(filter);
            }
        });

        return filters;
    }

    /**
     * @description
     * Resuseable pipe function used with MembersListService api service
     * that returns mobile and desktop configs with list of members populated from api response
     */
    public toMemberFilters(
        desktopFilterConfig: Filter[],
        mobileFilterConfig: MobileFilterConfig,
    ): UnaryFunction<unknown, Observable<{ mobileFilterConfigs: MobileFilterConfig; desktopFilterConfigs: Filter[] }>> {
        return pipe(
            filter(isNonNull),
            map((response: ApiListResponse<MemberModel>) => response.results),
            filter(isNonNull),
            map((memberResponse: MemberModel[]) => {
                const options: LabelValue[] = memberResponse.map((member: MemberModel) => ({
                    label: nameConcat(member.name),
                    value: member.member_number,
                }));

                return options;
            }),
            map((options: LabelValue[]) => ({
                mobileFilterConfigs: this.toMobileMemberFilter(mobileFilterConfig, options),
                desktopFilterConfigs: this.toDesktopMemberFilter(desktopFilterConfig, options),
            })),
        );
    }

    toDesktopMemberFilter(filters: Filter[], options: LabelValue[]): Filter[] {
        const desktopMemberFilter = filters.find((filter: Filter & { isMemberFilter: boolean }) => filter.isMemberFilter);

        if (!desktopMemberFilter) {
            this.noIsMemberFilterConfiguredWarning();

            return filters;
        }

        desktopMemberFilter.options.push(...options);

        return filters;
    }

    toMobileMemberFilter(mobileFilters: MobileFilterConfig, options: LabelValue[]): MobileFilterConfig {
        const mobileMemberFilter = mobileFilters.filters.find((filter: FilterGroup & { isMemberFilter: boolean }) => filter.isMemberFilter);

        if (!mobileMemberFilter) {
            this.noIsMemberFilterConfiguredWarning();

            return mobileFilters;
        }

        mobileMemberFilter.filters[0].options.push(...options);

        return mobileFilters;
    }

    noIsMemberFilterConfiguredWarning(): void {
        console.warn('isMemberFilter property was not found in the filter config object');
    }

    public setProductCoverageTypeFilter(filter: DropdownConfig): DropdownConfig {
        filter.options = [];
        const uniqueTypes: Set<string> = new Set<string>();
        this.policy.product_coverages.forEach((productCoverage: ProductCoverage) => uniqueTypes.add(productCoverage.product_coverage_type));

        uniqueTypes.forEach((uniqueType: string) =>
            filter.options.push({
                label: uniqueType,
                value: { product_coverage_type: uniqueType },
            }),
        );

        return filter;
    }

    public setProductCoverageNameFilter(filter: DropdownConfig, productCoverageType?: string): DropdownConfig {
        const pcType = productCoverageType ? productCoverageType.toLowerCase() : 'medical';
        const productCoverages = this.getProductCoverages(pcType);
        filter.options = [];
        productCoverages.map((productCoverage: ProductCoverage) => {
            filter.options.push({
                label: productCoverage.external_plan_name,
                value: {
                    product_coverage_id: productCoverage.product_coverage_id,
                    product_coverage_name: productCoverage.external_plan_name,
                },
            });
        });

        return filter;
    }

    public setUniqueProductCoverageNameFilter(filter: DropdownConfig, productCoverageType?: string): DropdownConfig {
        const pcType = productCoverageType ? productCoverageType.toLowerCase() : 'medical';
        const productCoverages = this.getProductCoverages(pcType);
        filter.options = [];

        const uniqueNames: Set<string> = new Set<string>();
        productCoverages.forEach((productCoverage: ProductCoverage) => uniqueNames.add(productCoverage.external_plan_name));

        uniqueNames.forEach((uniqueName: string) =>
            filter.options.push({
                label: uniqueName,
                value: { product_coverage_name: uniqueName },
            }),
        );

        return filter;
    }

    public setProductCoveragePeriodFilter(filter: DropdownConfig, productCoverageType?: string): DropdownConfig {
        const pcType = productCoverageType ? productCoverageType : 'medical';

        const productCoverages = this.getProductCoverages(pcType);
        this.policyAndBenefitsService.setCoveragePeriod(productCoverages);

        filter.options = [];
        productCoverages.map((productCoverage: ProductCoverage) => {
            filter.options.push({
                label: this.policyAndBenefitsService.formattedCoveragePeriod(productCoverage.coveragePeriod),
                value: {
                    effective_date: productCoverage.effective_date,
                    termination_date: productCoverage.termination_date,
                    product_coverage_id: productCoverage.product_coverage_id,
                    product_coverage_name: productCoverage.external_plan_name,
                },
            });
        });

        return filter;
    }

    public updateProfile(api: string, member: Member): Observable<any> {
        return this.http.patch(api, member);
    }

    /** Stateful provider and pharmacy search URL generator.
     * @param entityType The type of search in our provider search app to open. You can choose either provider (doctor) or pharmacy.
     * @param baseRoute What precedes the query parameters generated in this function. This is effectively static. */
    externalSearchUrl(entityType: 'provider' | 'pharmacy', baseRoute: Url): Url {
        try {
            const validAddress: Address = first(this.validAddresses(this.member_details.addresses));
            const validNetworkCode: NetworkCode = last(this.validNetworkCodes(this.getNetworkCodes())); // Last because it's most recent
            if (validAddress && validNetworkCode) return this.searchUrl(entityType, baseRoute, validNetworkCode, validAddress);
            // When there are no completely valid addresses but at least 1 has a ZIP code.
            if (exists(this.atLeastZipcode(this.member_details.addresses))) {
                const zipcodeOnly: Partial<Address> = {
                    zip_code: first(this.atLeastZipcode(this.member_details.addresses)).zip_code,
                };

                return this.searchUrl(entityType, baseRoute, defaultNetworkCode, zipcodeOnly as Address);
            }
        } catch (error) {
            // Pre-populated links should always open at this point because the defaults are literals defined in this file.
            this.logger.consoleError(
                '`externalSearchUrl`:  Using defaults because valid addresses or valid network codes do not exist: ',
                error,
            );
            const validAddress: Address = defaultAddress as Address;
            const validNetworkCode: NetworkCode = defaultNetworkCode;

            return this.searchUrl(entityType, baseRoute, validNetworkCode, validAddress);
        }
    }

    /** Helper to build the actual URL used for navigation. The parent `externalSearchUrl` is used to handle errors. */
    searchUrl(entityType: 'provider' | 'pharmacy', baseRoute: Url = '', networkCode: NetworkCode, address: Address): Url {
        if (exists(baseRoute)) this.logger.consoleWarn('`searchUrl`: Base route is static');
        baseRoute = 'https://search.ucare.org/provider-search/search';

        return [
            baseRoute,
            [
                ['entity_type', entityType],
                ['distance', '60'],
                ['network_code', networkCode],
                ['location', address.zip_code],
                // Handle `zipcodeOnly`. Provider search still works without latitude and longitude and only `location=`zip_code``.
                exists(address.lat) ? ['latitude', address.lat.toString()] : undefined,
                exists(address.lng) ? ['longitude', address.lng.toString()] : undefined,
            ]
                .map((queryParam: [string, string]) => queryParam.join('='))
                .join('&'),
        ].join('?');
    }

    /** FE patch for BE that returns different address types in the same `addresses` array. */
    validAddresses(addresses: Address[]): Address[] {
        if (none(addresses)) this.noMemberDetailsAddressesExist();
        const validAddresses: Address[] = addresses.filter((a: Address) => exists(a.zip_code) && exists(a.lat) && exists(a.lng));
        if (none(addresses)) throw new Error('Addresses exist in member details but not all contain `zipcode`, `lat` and `lng`.');

        return validAddresses;
    }

    /** Addresses that at least contain a ZIP code if not latitude and longitude. */
    atLeastZipcode(addresses: Address[]): Address[] {
        if (none(addresses)) this.noMemberDetailsAddressesExist();
        const atLeastZipcodeAddresses: Address[] = addresses.filter((a: Address) => exists(a.zip_code));
        if (none(atLeastZipcodeAddresses)) throw new Error('Addresses exist in member details but not all contain `zipcode`.');

        return atLeastZipcodeAddresses;
    }

    /** FE patch for BE that returns different address types in the same `addresses` array.
     * We don't need to filter the network codes because they are field literals in this service class. */
    validNetworkCodes(networkCodes: NetworkCode[]): NetworkCode[] {
        if (none(networkCodes)) throw new Error('No network codes exist in the member service.');

        return networkCodes;
    }

    /** When no addresses exist in member details */
    noMemberDetailsAddressesExist(): never {
        throw new Error('No addresses exist in member details.');
    }

    public getMemberDetails(): Observable<MemberDetails> {
        if (this.memberId) {
            const url = stringBuilder('api/enrollment/members/${member_id}/', { member_id: this.memberId });

            return this.http.get<MemberDetails>(url);
        } else return EMPTY;
    }

    getAllMembers(queryParams: AllMemberQueryParams): Observable<AllMembers> {
        const params = {};
        Object.keys(queryParams).forEach((key: string) => (params[key] = queryParams[key]));

        return this.http.get<AllMembers>(this.configService.getPageConfig<{ allMembersApi: string }>('global').allMembersApi, { params });
    }

    public getPolicyData(): Observable<MemberPolicy[]> {
        const api = 'api/member-portal/policies/';
        const params = { member_number: this.memberId };

        return this.http.get<MemberPolicy[]>(api, { params });
    }

    public getMemberPlanSummary(): Observable<ApiListResponse<any>> {
        const config = this.configService.getPageConfig<BenefitsConfig>('benefits') as any;
        const params = { member_id: this.memberId };
        if (this.currentPolicyService.currentPolicy$.value.id !== noPolicyIsCurrentlySelected) {
            params['policy_id2'] = `${this.currentPolicyService.currentPolicy$.value.id}`;
        }
        const url = stringBuilder(config.api.endpoint, { params });

        return this.http.get<ApiListResponse<any>>(url);
    }

    public getMembers(memberCoverages: MemberCoverage[], error_func: any): Member[] {
        let members = [];
        try {
            for (const memberCoverage of memberCoverages) {
                members = [...members, memberCoverage.member];
            }

            return members;
        } catch (error) {
            error_func(error);
        }
    }

    public getMemberBenefits(member_id: any, params?: any): Observable<ApiListResponse<MemberBenefits>> {
        if (!member_id) {
            member_id = this.memberId;
        }
        if (this.currentPolicyService.currentPolicy$.value.id !== noPolicyIsCurrentlySelected) {
            params['policy_id4'] = `${this.currentPolicyService.currentPolicy$.value.id}`;
        }
        const config = this.configService.getPageConfig<AccountConfig>('account') as any;
        const url = stringBuilder(config.api.getBenefitsEndpoint, { member_id });

        return this.http.get<ApiListResponse<any>>(url, { params });
    }

    public getMemberBenefitPeriods(member_id: any, params?: any): Observable<ApiListResponse<MemberBenefitPeriods>> {
        if (!member_id) member_id = this.memberId;
        const config = this.configService.getPageConfig<AccountConfig>('account') as any;
        const url = stringBuilder(config.api.getBenefitPeriodsEndpoint, { member_id });

        return this.http.get<ApiListResponse<any>>(url, { params });
    }

    public getMemberClaims(): Observable<Claim[]> {
        const config = this.configService.getPageConfig<ClaimsConfig>('claims') as any;

        return this.claims.length
            ? of(this.claims)
            : this.http.get<ApiListResponse<any>>(config.api.endpoint).pipe(
                  map((response: ApiListResponse<any>) => response.results),
                  tap((claims: any[]) => (this.claims = claims)),
              );
    }

    public getClaimDetails(claimId: string): Observable<any> {
        return this.getMemberClaims().pipe(
            map((claims: Claim[]) => claims.find((claim: Claim) => String(claim.claim_number) === String(claimId)) || {}),
        );
    }

    public setMemberDetails(memberDetails: MemberDetails) {
        this.member_details = { ...memberDetails };
        this.memberDetails$.next({ ...memberDetails });
    }

    getNetworkCodes(): ProductCoverage['network_codes'] {
        return this.policy.network_codes;
    }

    /** TODO(jduong): cache observable in order to subscribe to same one in multiple places. */
    initMemberData(): void {
        if (!this.member_details) {
            this.getMemberDetails().subscribe(
                (member: MemberDetails) => {
                    this.setMemberDetails(member);
                },
                (error: unknown) => {
                    this.logger.consoleWarn('Failed to get member', error);
                },
            );
        }
    }

    getMemberList(api: string, params?: PaginatorParams & any): Observable<MemberDetails[]> {
        return this.http
            .get<ApiListResponse<MemberDetails>>(api, { params: params as Params, headers: { 'skip-request-cache': 'config' } })
            .pipe(
                map((res: any) => {
                    this.memberCount$.next(res?.count || 0);

                    return res.results;
                }),
                catchError((err: unknown) => {
                    console.error(err);

                    return of(err);
                }),
            );
    }
}
