import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addressDisplay',
})
export class AddressDisplayPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value.street_name_1) {
            return '';
        } else {
            return `${value.street_name_1 || value.address1} ${value.street_name_2 || value.address2} ${value.city || value.city_name} ${
                value.county || value.county_name
            }, ${value.state} ${value.zip_code || value.zip}`;
        }
    }
}
