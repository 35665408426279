import { DOCUMENT, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, Renderer2, RendererFactory2, Inject, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './shared/config.service';
import { DynamicRoutingService } from './shared/services/dynamic-routing.service';
import { AppConfig } from './app.config';
import { MemberPortalConfig } from './member-portal/member-portal.component';

const MinimumEdgeVersion = 76;
const EdgeNameLength = 5;

/** Root level component for the app */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    config: AppConfig;
    renderer: Renderer2;

    constructor(
        public locationRef: Location,
        private titleService: Title,
        private configService: ConfigService,
        private dynamicRouting: DynamicRoutingService,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: any,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    ngOnInit(): void {
        if (!this.browserIsSupported() && this.configService.configs.APP?.support?.unsupportedBrowserModalEnabled) {
            alert(
                'We have detected that the browser you are using is not fully supported. ' +
                    'Certain features may not function properly. We do support the following browsers: ' +
                    'Chrome, Edge, Firefox and Safari',
            );
        }
        this.dynamicRouting.entry_url = this.locationRef.path();
        const { title, gaScript } = this.configService.getPageConfig<MemberPortalConfig>('global');
        if (title) this.titleService.setTitle(title);
        if (gaScript) this.renderWindowScript(this.scriptElement(gaScript));
    }

    scriptElement(innerHtml?: string) {
        const scriptElement = this.renderer.createElement('script');
        scriptElement.type = 'application/javascript';
        if (innerHtml) scriptElement.innerHTML = innerHtml;

        return scriptElement;
    }

    renderWindowScript(scriptCallback: unknown): void {
        return this.renderer.appendChild(document.head, scriptCallback);
    }

    browserIsSupported() {
        return !this.isIE() && !this.isIE11() && !this.isOpera() && this.isSupportedEdge();
    }

    isIE() {
        return navigator.userAgent.indexOf('MSIE ') > -1;
    }

    isIE11() {
        return navigator.userAgent.indexOf('Trident/') > -1;
    }

    isOpera() {
        return navigator.userAgent.indexOf('Opera/') > -1 || navigator.userAgent.indexOf('OPR/') > -1;
    }

    isSupportedEdge() {
        const edge = navigator.userAgent.indexOf('Edge/');
        if (edge > 0) {
            const edgeVersion = parseInt(navigator.userAgent.substring(edge + EdgeNameLength, navigator.userAgent.indexOf('.', edge)), 10);

            return edgeVersion > MinimumEdgeVersion;
        }

        return true;
    }
}
