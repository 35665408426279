import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZipFormControlService } from './shared/zipui-shared-module/zip-form-control/zip-form-control.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MemberService } from './member-portal/modules/shared/services/member.service';
import { AuthService } from './shared/auth.service';
import { ConfigService, Configs } from './shared/config.service';
import { AnalyticsService } from './shared/services/analytics.service/analytics.service';
import { LanguagePreferenceService } from './shared/services/language-preference.service/language-preference.service';
import { DEFAULT_TIMEOUT, RequestTimeoutInterceptor } from './shared/services/request-timeout.interceptor';
import { SentryErrorHandler } from './shared/services/sentry.service';
import { SharedModule } from './shared/shared.module';
import { WINDOW_PROVIDERS } from './shared/utilities/Window';
import { DuplicateRequestInterceptor } from './shared/services/duplicate-request-interceptor';
import { CacheRequestResponseInterceptor } from './shared/services/cache-request-response.interceptor';

/**
 * Get user and config data
 */
function resolveConfigUserData(configService: ConfigService, authService: AuthService): Function {
    const configPromise = configService.getApplicationConfig();
    const userPromise = Promise.resolve();
    return () => {
        // Ensures logged in user set only after config and user data fetched
        return Promise.all([configPromise, userPromise]).then(([config]: [object, void]) => {
            configService.configs = config;
            authService.setAppConfig(config as Configs);
        });
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [AppRoutingModule, BrowserModule, BrowserAnimationsModule, HttpClientModule, SharedModule.forRoot()],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: resolveConfigUserData,
            deps: [ConfigService, AuthService],
            multi: true,
        },
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: RequestTimeoutInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: DuplicateRequestInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: CacheRequestResponseInterceptor,
                multi: true,
            },
        ],
        [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        Title,
        AnalyticsService,
        LanguagePreferenceService,
        WINDOW_PROVIDERS,
        MemberService,
        ZipFormControlService,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
