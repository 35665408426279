import { ZipFormControlI } from './../../../shared/zipui-shared-module/zip-form-control/models/zip-form-control';
import { DropdownConfig } from './../shared/components/defaulted-dropdown/dropdownConfig.types';
import { PrimaryCareProvider } from './medical/medical.types';
/* eslint-disable @typescript-eslint/naming-convention */

export interface Tab {
    tabName?: string;
    label: string;
    customTemplate: string;
    hideContactUs: boolean;
    description: string;
    ucareSpecialPharmacyTab?: boolean;
    planBenefitsPharmacyTab?: boolean;
    vspDescription: string;
    vspPlanName: string;

    /**
     * Link to go to when learn 2024 benefit changes link is clicked
     */
    newBenefitChangesLink?: string;

    /**
     * Link to go to when the pharmacy url is clicked
     */
    pharmacyLink?: string;

    /**
     * Express scripts SSO link to use
     */
    expressScriptsLink?: string;

    /**
     * SSO link for an external website, typically using Okta (which should appear in the URL)
     */
    externalSSOLink?: string;
    /**
     * Link to go to when an exception request is clicked
     */
    exceptionRequestLink?: string;

    exceptionRequestLinkMedicare?: string;
    exceptionRequestLinkMedicaid?: string;
    exceptionRequestLinkIFP?: string;
}

export type Label = string;

/** Default medical benefits component config */
export const defaultConfig: MedicalBenefitsComponentConfig = {
    tabCategories: {
        medical: true,
        pharmacy: true,
        dental: true,
        vision: true,
    },
    plan: {
        description: true,
        name: true,
        coveragePeriod: true,
        effectiveDate: true,
    },
    memberCard: {
        firstName: true,
        lastName: true,
        formattedName: true,
        memberID: true,
        relationshipToSubscriber: true,
        policyStatus: true,
        memberEffectiveDate: true,
        pcpList: true,
    },
    planBenefits: {
        benefitName: true,
        inNetworkBenefitValue: true,
        outOfNetworkBenefitValue: true,
        text: true,
    },
    planDropdownConfig: {
        label: 'Plan Name',
        options: [],
        type: 'dropdown',
        key: 'plan_name',
        labelTemplate: {
            optionalText: false,
        },
        minWidth: 12,
        enabled: true,
    },
    periodDropdownConfig: {
        label: 'Coverage Period',
        options: [],
        type: 'dropdown',
        key: 'coverage_period',
        labelTemplate: {
            optionalText: false,
        },
        minWidth: 12,
        enabled: true,
    },
};

export interface MedicalBenefitsComponentConfig {
    tabCategories: {
        medical: boolean;
        pharmacy: boolean;
        dental: boolean;
        vision: boolean;
    };
    plan: {
        description: boolean;
        name: boolean;
        coveragePeriod: boolean;
        effectiveDate: boolean;
    };
    memberCard: {
        firstName: boolean;
        lastName: boolean;
        formattedName: boolean;
        memberID: boolean;
        relationshipToSubscriber: boolean;
        policyStatus: boolean;
        memberEffectiveDate: boolean;
        pcpList: boolean;
    };
    planBenefits: {
        benefitName: boolean;
        inNetworkBenefitValue: boolean;
        outOfNetworkBenefitValue: boolean;
        text: boolean;
        errorMessage?: string;
        memberPeriodBenefits?: MemberPeriodBenefitsConfig;
        formularyNetworkUrlConfig?: string;
    };
    planDropdownConfig: DropdownConfig;
    periodDropdownConfig: DropdownConfig;
    customMessageHtml?: string;
    PTHPPlanMessageForDentalTab?: string;
    PTHPPlanMessageForVisionTab?: string;
    noTerminationDateText?: string;
}

export interface MemberPeriodBenefitsConfig {
    membersDropdownConfig: ZipFormControlI;
    periodsDropdownConfig: ZipFormControlI;
    noBenefitsText?: string;
}

export interface Plan {
    name: string;
    coveragePeriod: CoveragePeriod;
    effectiveDate: Date;
    details: PlanDetail[];
}

export type CoveragePeriod = [Date, Date];

export interface PlanDetail {
    benefitName: string;
    inNetworkBenefitValue: string;
    outOfNetworkBenefitValue: string;
}

export interface BenefitsComponentConfig {
    tabs: Tab[];
    showTabsOnlyInResponse: boolean;
}

export enum PlanType {
    Medical = 'medical',
    Dental = 'dental',
    Vision = 'vision',
    Pharmacy = 'drug',
}

export interface MemberDetails {
    tenant: string;
    email_address: string;
    home_phone: string;
    created_at: string;
    member_number: string;
    secondary_language: string;
    email: string;
    addresses: Address[];
    issuer_assigned_member_id: string;
    tobacco_use: boolean;
    effective_date: unknown;
    ffe_assigned_member_id: string;
    name: Name;
    primary_language: string;
    first_name: string;
    prefix_name: string;
    updated_at: string;
    marital_status: string;
    crm_id: string;
    dhs_number: string;
    cell_phone: string;
    weight: string;
    info_method: string;
    birth_date: string;
    termination_date: unknown;
    employer_name: string;
    last_date_of_tobacco_use: unknown;
    gender: string;
    primary_phone_type: string;
    tribal_id: string;
    disabled: boolean;
    privacy_restriction: boolean;
    ssn: string;
    office_phone: string;
    ethnicity: string;
    suffix_name: string;
    last_name: string;
    id: number;
    height: string;
    terminated_date: unknown;
    member_name: string;
    middle_name: string;
}

export interface Policies {
    next: unknown;
    results: PolicyResult[];
    previous: unknown;
    count: number;
}

export interface PolicyResult {
    renewal_pending: false;
    crm_id: string;
    groups: unknown[];
    agents: unknown[];
    policy_number: string;
    id: number;
    household_size: 1;
    policy_status: string;
    updated_at: string;
    created_at: string;
    child_only: false;
    policy_status_reason: string;
    policy_premium: string;
    line_of_business: string;
    termination_date: string;
    contacts: unknown[];
    tenant: string;
    product_coverages: ProductCoverage[];
    renewal_status: string;
    policy_name: string;
    household_type: string;
    effective_date: unknown;
}

export interface ProductCoverage {
    external_plan_name: string;
    external_plan_id: string | number;
    product_coverage_id: string | number;
    product_coverage_type: string;
    termination_date: string;
    effective_date: string;
    member_coverages: MemberCoverage[];
    coveragePeriod?: CoveragePeriod;
    planBenefits?: PlanBenefit[];
    mergedBenefits?: MergedPlanBenefit[];
    /*
     ** soon to be deprecated props
     */
    plan_information: any;
    product_coverage_type_display: any;
    network_codes: string[];
    pcp_details: [];
    formulary_network_url: string;
}

export interface MemberCoverage {
    external_plan_id: string;
    product_coverage: number;
    proposed_effective_date: string;
    crm_id: string;
    der_date: string;
    plan_identifier: string;
    ineligibility_end_date: string;
    cobra_status: string;
    member: Member;
    id: number;
    der_receipt_date: string;
    group: unknown;
    status_description: string;
    enrollment_status: string;
    created_at: string;
    application_receipt_date: string;
    updated_at: string;
    auto_disenrollment_scheduled_date: unknown;
    ineligibility_start_date: string;
    enrollment_id: string;
    application_signature_date: string;
    termination_date: string;
    status_code: string;
    auto_disenrollment_pending: false;
    pbp_code: string;
    tenant: string;
    relationship_to_subscriber: string;
    member_coverage_id: string;
    termination_description: string;
    subscriber: true;
    application_date: string;
    pbp_description: string;
    reinstatement_date: string;
    record_type: string;
    auto_disenrollment_cancelled_date: unknown;
    plan_code: string;
    termination_reason_code: string;
    auto_disenrollment_effective_date: unknown;
    auto_disenrollment_reason: string;
    effective_date: string;
    coveragePeriod?: CoveragePeriod;
    name?: Name;
    is_pcp: boolean;
    hasActivePCP?: boolean;
}

export interface Member {
    member_display_identifier: string;
    relationship_to_subscriber: string;
    subscriber_number: string;
    name: Name;
    birth_date: string;
    member_number: string;
    home_phone: string;
    addresses: Address[];
    formatted_name?: string;
    prefix_name: string;
    gender: string;
    info_method: string;
    suffix_name: string;
    member_name: string;
    height: string;
    ssn: string;
    last_date_of_tobacco_use: unknown;
    terminated_date: unknown;
    id: number;
    crm_id: string;
    ethnicity: string;
    last_name: string;
    privacy_restriction: boolean;
    updated_at: string;
    middle_name: string;
    office_phone: string;
    tribal_id: string;
    weight: string;
    secondary_language: string;
    tobacco_use: boolean;
    termination_date: unknown;
    first_name: string;
    ffe_assigned_member_id: string;
    tenant: string;
    cell_phone: string;
    email: string;
    marital_status: string;
    dhs_number: string;
    disabled: boolean;
    email_address: string;
    employer_name: string;
    primary_language: string;
    created_at: string;
    issuer_assigned_member_id: string;
    primary_phone_type: string;
    effective_date: unknown;
    has_disability: boolean | string;
    pcp_list?: PrimaryCareProvider[];

    firstName: string;
    lastName: string;
    memberID: string; // External plan ID
    relationshipToSubscriber: string;
    policyStatus: string;
    planStatus?: string;
    memberEffectivePeriod: string | Date;
    memberEffectiveStatus: Date;
    pcp: string; // Provider name on record
    pcp_details: any;
    full_name?: string;
}

export interface Address {
    termination_date: unknown;
    attention: string;
    created_at: string;
    tenant: string;
    zip_code: string;
    title: string;
    id: number;
    state: string;
    county_name: string;
    county_code: string;
    street_name_2: string;
    city_name: string;
    street_name_3: string;
    updated_at: string;
    address_type: string;
    effective_date: unknown;
    street_name_1: string;
    type: string;
    lat: number;
    lng: number;
}

export interface Name {
    suffix: string;
    last_name: string;
    first_name: string;
    prefix: string;
    middle_name: string;
}

export interface PlanInformation {
    plan_year: string;
    hios_id: string;
    name: string;
    deductible: number;
    optional_plan_types: unknown[];
    plan_benefits: unknown[];
    max_out_of_pocket: number;
    benefits: Benefit[];
    id: number;
    product_type: string;
}
export interface Benefit {
    value: string;
    label: string;
    member_coverages: MemberCoverage[];
    total: string;
    termination_date: string;
    accumulators: Accumulator[];
    created_at: string;
    title: string;
    benefit_applies_to: string;
    effective_date: string;
    accumulator_type: string;
    benefit_category: string;
    updated_at: string;
    coverage_level: string;
    benefit_type: string;
    benefit_period: BenefitPeriod;
    notes: string[];
    id: number | string;
    description: string;
    participation_level: string;
}

export interface Accumulator {
    type?: string;
    value: string;
}

export interface BenefitPeriod {
    id: number | string;
    start_date: string;
    end_date: string;
}

export interface PlanBenefit {
    benefit_applies_to: string;
    benefit_category_display_text: string;
    benefit_category: string;
    benefit_display_text: string;
    benefit_service_id: string;
    benefit_unit_max: string;
    benefit_unit_type: string;
    benefit_unit_value: string;
    co_insurance: string;
    co_pay_pcp: string;
    co_pay_specialist: string;
    co_payment: string;
    created_at: string;
    deductible: string;
    effective_date: string;
    id: number;
    limitation_display: string;
    member_coverage: MemberCoverage;
    out_of_pocket: string;
    participation_level: string;
    plan: number;
    prior_auth_required: boolean;
    priority_weighting: number;
    referral_required: boolean;
    tenant: string;
    termination_date: string;
    updated_at: string;
    plan_id: string;
}

export interface MergedPlanBenefit {
    benefit_category?: string;
    benefit_category_display_text?: string;
    participations?: Participation[];
}

export interface Participation {
    participation_level?: string;
    benefit_display_text?: string;
}

export const defaultPlanBenefitsErrorMessage = 'Your data cannot be loaded due to technical issues. Please try again.';
