import { Injectable } from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { stringBuilder } from '../string-builder';
import { ZipFormControl } from './models/zip-form-control';
import ZipFilters from './zip-form-control.filters';

interface Validator {
    label: string;
    config: object;
    length: number;
    errorMessage: string;
    compareTo?: string;
    key?: string;
}

/**
 * Nominally a functioning service around form controls
 */
@Injectable()
export class ZipFormControlService {
    validators: object = {};
    templates: object = {};
    changes: Subject<any> = new Subject<any>();
    compareToPairs: object = {};

    constructor() {
        this.registerDefaultValidators();
    }

    public registerTemplate(templateKey: string, templateRef: any): void {
        if (!templateRef) {
            throw new Error('A template ref must be provided to register a template.');
        }

        if (!templateKey) {
            throw new Error('A key must be provided to register a template.');
        }

        this.templates[templateKey] = templateRef;
    }

    registerDefaultValidators(): void {
        this.registerValidator('required', () => Validators.required, '${label} is required.');
        this.registerValidator('requiredTrue', () => Validators.requiredTrue, 'Required.');
        this.registerValidator('date', () => ZipFilters.dateRange, 'Please enter a valid date');
        this.registerValidator('email', ZipFilters.emailPattern, 'Please enter a valid email address');
        this.registerValidator('phone', ZipFilters.phonePattern, 'Please enter a valid phone number.');
        this.registerValidator('fourDigitsOnly', ZipFilters.fourDigitsOnly, '${label} allows four digits only.');
        this.registerValidator('fax', ZipFilters.phonePattern, 'Please enter a valid fax number.');
        this.registerValidator('increment', ZipFilters.numberIncrementPattern, 'Please enter a valid date');
        this.registerValidator('minLength', Validators.minLength, '${label} has a minimum length of ${length}.');
        this.registerValidator('maxLength', Validators.maxLength, '${label} has a maximum length of ${length}.');
        this.registerValidator('min', Validators.min, '${label} has a minimum value of ${config}.');
        this.registerValidator('max', Validators.max, '${label} has a maximum value of ${config}.');
        this.registerValidator('decimal', ZipFilters.decimalPlaceValidator, 'This field accepts a maximum of ${config} decimal place.');
        this.registerValidator('equals_value', ZipFilters.equals_value, 'Equals');
        this.registerValidator('sameAs', ZipFilters.sameAs, '${label} should be same as ${compareTo}', true);
        this.registerValidator('differentFrom', ZipFilters.differentFrom, '${label} should be different from ${config.label}', true);
        // This is another copy of different from field. i.e. if we don't want to allow password same as last name and firstname then we will need another rule.
        this.registerValidator('differentFrom2', ZipFilters.differentFrom2, '${label} should be different from ${config.label}', true);
        this.registerValidator('optionalRequired', ZipFilters.optionalRequired, 'This field is required', true);
        this.registerValidator('hasUpperCase', ZipFilters.upperCase, '${label} requires at least one uppercase character.');
        this.registerValidator('hasLowerCase', ZipFilters.lowerCase, '${label} requires at least one lowercase character.');
        this.registerValidator('hasInteger', ZipFilters.integer, '${label} requires at least one numeric character.');
        this.registerValidator('hasSpecChar', ZipFilters.specialChar, '${label} requires at least one non-alphanumeric character.');
        this.registerValidator(
            'password',
            ZipFilters.password,
            'Passwords must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 digit, and 1 special character.',
        );
        this.registerValidator('numOnly', ZipFilters.numberOnly, '${label} only accepts numeric values');
        this.registerValidator('isCurrency', ZipFilters.isCurrency, '${label} only accepts numeric values in currency format.');
    }

    registerValidator(key: string, validator: any, errorMessage: string, formGroup: boolean = false): void {
        const validatorEntry = this.validators[key];
        if (validatorEntry) {
            validatorEntry.validator = validator;
        }
        this.validators[key] = { key, validator, errorMessage, formGroup };
    }

    generateFormControlValidators(formGroup: FormGroup, config: any, formControlConfig: any): any[] {
        const validators = [];

        // This looks funky -- needs to be refactored properly or explained
        if (config.date) {
            if (config.date) {
                validators.push(ZipFilters.dateRange(formGroup, config.date.before));
            }
        }

        for (const key in this.validators) {
            if (this.validators.hasOwnProperty(key)) {
                const validator = this.validators[key];
                const validatorConfig = config[key];
                if (validatorConfig) {
                    validator.config = validatorConfig;
                    let vFunction;
                    if (validator.formGroup) {
                        if (key === 'sameAs' || key === 'differentFrom' || key === 'differentFrom2') {
                            validatorConfig.self = formControlConfig.key;
                            if (key === 'sameAs') {
                                this.compareToPairs[validatorConfig.self] = validatorConfig.prop;
                            }
                        }
                        vFunction = validator.validator(formGroup, validatorConfig);
                    } else {
                        vFunction = validator.validator(validatorConfig);
                    }
                    if (!this.isFunction(vFunction)) {
                        console.warn(`Validator '${key}' does not return a validator function and is being ignored`);
                    } else {
                        validators.push(vFunction);
                    }
                }
            }
        }

        return validators;
    }

    isFunction(functionToCheck: unknown): boolean {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    /**
     * Use error message provided by the error Object if exist otherwise use the default one.
     */
    getErrorMessage(error: { message?: string }, validator: Validator): any {
        return error && error.message
            ? error.message
            : stringBuilder(validator.errorMessage, {
                  config: validator.config,
                  label: validator.label,
                  length: validator.length,
                  compareTo: this.compareToPairs[validator.key],
              });
    }

    /**
     * add error message to the message array to display
     */
    public validationErrorToMessages(
        validationErrors: string[] | { required: { message: string } }[],
        zipFormControl: ZipFormControl,
    ): string[] {
        const errorMessages: string[] = [];
        if (!validationErrors) {
            return errorMessages;
        }
        Object.keys(validationErrors).forEach((validationErrorType: string) => {
            // check if an error type is found
            let validator;
            for (const key in this.validators) {
                if (key.toLowerCase() === validationErrorType.toLowerCase()) {
                    validator = this.validators[key];
                    break;
                }
            }

            if (!validator) {
                return;
            }

            validator.label = zipFormControl.shortLabel ? zipFormControl.shortLabel : zipFormControl.label;
            validator.key = zipFormControl.key;

            if (zipFormControl.type === 'radio' && validationErrorType === 'required') {
                (validationErrors as any).required = {
                    message: `Select one of the ${validator.label} options `,
                };
            }

            if (validationErrorType === 'maxlength') {
                validator.length = zipFormControl ? zipFormControl.validators.maxLength : '';
            } else if (validationErrorType === 'minlength') {
                validator.length = zipFormControl ? zipFormControl.validators.minLength : '';
            }

            errorMessages.push(this.getErrorMessage(validationErrors[validationErrorType], validator));
        });

        return errorMessages;
    }

    public emit(data: any): void {
        this.changes.next(data);
    }
}
