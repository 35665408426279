import { Pipe, PipeTransform } from '@angular/core';
import { nameConcat } from '../utilities/NameConcat';

@Pipe({
    name: 'last-name-first-name',
})
export class LastNameFirstNamePipe implements PipeTransform {
    transform(name: { first_name: string; last_name: string }, ...args: any[]): string {
        let concatenatedName = '';
        // When there lastt name and First name both add ,
        if (name.last_name && name.first_name) {
            concatenatedName = `${name.last_name}, ${name.first_name}`;
        } else if (name.first_name) {
            concatenatedName = `${name.first_name}`;
        } else if (name.last_name) {
            concatenatedName = `${name.last_name}`;
        }

        return concatenatedName.replace(/\s+/g, ' ').trim();
    }
}
