import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

declare const process;

try {
    if (process.env.isProd) enableProdMode();
} catch (e) {}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: Error) => console.warn(err));
