import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { AuthService } from '../auth.service';
import { ConfigService } from '../config.service';
import { Undetermined } from '../utilities/Undetermined';
import { CacheRequestResponseInterceptor } from './cache-request-response.interceptor';

interface GlobalConfig {
    internalSessionTimeout: Undetermined;
}

@Injectable()
export class SessionService {
    configuration;

    // TODO un hardcode these options
    private count = 0;
    private timeout_seconds = 1800;
    private timeout_multiplier = 1000;
    private timerSubscription: Subscription;
    private inifiteSubscription: Subscription;
    private timer: Observable<number>;
    private infiniteTimer: Observable<number>;
    private resetOnTrigger = false;
    public timeoutExpired: Subject<number> = new Subject<number>();

    constructor(private configService: ConfigService, private authService: AuthService, private http: HttpClient) {
        this.getConfigurations();
        if (this.configuration) {
            if (this.configuration.active) {
                this.startTimer();
            }
            if (this.configuration.keepAlive) {
                this.startKeepAlive();
            }
        }
    }

    /**
     * Starting a time using an observable thats less than the session timeout
     * Probably a dumb way to handle this but it needs to be done in a day and works.
     */
    public startKeepAlive() {
        if (this.inifiteSubscription) {
            this.inifiteSubscription.unsubscribe();
        }

        this.infiniteTimer = timer((this.timeout_seconds - 60) * this.timeout_multiplier);
        this.inifiteSubscription = this.infiniteTimer.subscribe((n) => {
            // reset configs to make sure role hasnt changed configurations
            this.getConfigurations();
            this.http
                .get('/user/keep-alive/', {
                    headers: new HttpHeaders().set(CacheRequestResponseInterceptor.SKIP_CACHE_KEY, 'true'),
                })
                .subscribe((res) => {
                    this.resetTimer();
                    if (this.configuration.keepAlive) {
                        this.startKeepAlive();
                    }
                });
        });
    }

    /**
     * Starting a time using an observable
     */
    public startTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timer = timer(this.timeout_seconds * this.timeout_multiplier);
        this.timerSubscription = this.timer.subscribe((n) => {
            this.timerComplete(n);
        });
    }

    /**
     * Bumping timer to full count again
     */
    public resetTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        if (this.configuration) {
            this.timer = timer(this.timeout_seconds * this.timeout_multiplier);
            this.timerSubscription = this.timer.subscribe((n) => {
                this.timerComplete(n);
            });
        }
    }

    /**
     * Finishing timer and making call
     */
    private timerComplete(n: number) {
        this.timeoutExpired.next(++this.count);

        if (this.resetOnTrigger) {
            this.startTimer();
        }
    }

    /**
     * Needed incase the user role changes mid session / updating the configuration (e.g.: Anon To Client)
     * @private
     */
    private getConfigurations() {
        try {
            this.configuration = this.configService.getPageConfig<GlobalConfig>('global').internalSessionTimeout;
        } catch (err) {
            console.warn(`Role: ${this.authService.userRole} most likely not set up properly in config.json for internal session timeout`);
            this.configuration = this.configService.getPageConfig<GlobalConfig>('global').internalSessionTimeout;
        }
    }
}
