import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, asyncScheduler, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { RequestTrackingService } from './request-tracking.service';

@Injectable()
export class DuplicateRequestInterceptor implements HttpInterceptor {
    static SKIP_DUPLICATE_REQUEST_HEADER = 'skip-duplicate-request';
    constructor(private requestTrackingService: RequestTrackingService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !req.headers.has(DuplicateRequestInterceptor.SKIP_DUPLICATE_REQUEST_HEADER) &&
            this.requestTrackingService.contains(req.urlWithParams, req.body)
        ) {
            return EMPTY;
        }

        this.requestTrackingService.add(req.urlWithParams, req.body);

        return next.handle(req).pipe(
            tap((evt: any) => {
                if (evt instanceof HttpResponse) {
                    this.requestTrackingService.remove(req.urlWithParams, req.body);
                }
            }),
            catchError((err: unknown) => {
                this.requestTrackingService.remove(req.urlWithParams, req.body);

                return throwError(err, asyncScheduler);
            }),
        );
    }
}

export const DUPLICATE_REQUEST_PROVIDER: any = {
    provide: HTTP_INTERCEPTORS,
    useClass: DuplicateRequestInterceptor,
    multi: true,
};
