import { Pipe, PipeTransform } from '@angular/core';
import { getValue } from '../zipui-shared-module/value-getter';

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }
        let first_name;
        let last_name;
        if (args && args.hasOwnProperty('first_name') && args.hasOwnProperty('last_name')) {
            // gets values from teh given object utilizing the args given
            first_name = getValue(value, args.first_name);
            last_name = getValue(value, args.last_name);
        } else {
            first_name = value.first_name ? value.first_name : '';
            last_name = value.last_name ? value.last_name : '';
        }

        return first_name && last_name ? `${first_name} ${last_name}` : '';
    }
}
