import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { hash } from '../utilities/String';

@Injectable({
    providedIn: 'root',
})
export class RequestCacheService {
    readonly requestCache: { [key: string]: HttpResponse<any> } = {};
    get(key): HttpResponse<any> {
        return this.requestCache[hash(key)];
    }

    put(key, responseToCache: HttpResponse<any>): void {
        this.requestCache[hash(key)] = responseToCache;
    }

    delete(key): void {
        delete this.requestCache[hash(key)];
    }
}
