import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MedicalBenefitsComponentConfig, defaultConfig } from '../member-portal/modules/benefits/benefits.types';
import { AppConfig } from '../app.config';
import { Unreliable } from './utilities/Type';
import { cloneObject } from './zipui-shared-module/object-utils';

/** The response from the config API. This is what you get when you request `/config/` in the client. */
export interface Configs {
    ANGULAR_PARAMS: unknown;
    APP_NAME: unknown;
    APP: AppConfig;
    ASSETS_BASE_URL: unknown;
    CSRF_TOKEN: unknown;
    SESSION_COOKIE_AGE_ANONYMOUS: unknown;
    SESSION_COOKIE_AGE_AUTHENTICATED: unknown;
    SESSION_COOKIE_AGE: unknown;
    TENANT_NAME: unknown;
    USER: unknown;
    VARIATION: unknown;
}

/** Fetches config specifically from API. */
@Injectable()
export class ConfigService {
    public configs: any;
    public configs$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private http: HttpClient) {}

    /** You can change what will get returned here using the `cacheConfigs` method. */
    public getPageConfig<T>(page: string): T {
        return this?.configs?.['APP']?.[page] || '';
    }

    public getApplicationConfig(): Promise<Configs> {
        return this.http
            .get<Configs>('config/', { headers: { 'skip-request-cache': 'config' } })
            .toPromise();
    }

    getApplicationConfig$(): Observable<Configs> {
        return this.http.get<Configs>('config/', { headers: { 'skip-request-cache': 'config' } });
    }

    /** Update the `configs` field. Use this to handle config race condition. We use this in `MemberDataResolver` to set the configs
     * that will be accessed by the components using `getApplicationConfig` so that they will have a config with the applicable cohorts
     * resolve. If we do not use it there, then cohort configs will not be available and every call to `getPageConfig` will return the
     * generic tenant config. */
    cacheConfigs(applicationConfig: Configs): void {
        this.configs = applicationConfig;
    }

    initConfig(pageConfig: Unreliable<MedicalBenefitsComponentConfig>): MedicalBenefitsComponentConfig {
        const medicalBenefitsComponentConfig: MedicalBenefitsComponentConfig = this.toValidConfig(pageConfig);

        return {
            tabCategories: medicalBenefitsComponentConfig.tabCategories,
            plan: medicalBenefitsComponentConfig.plan,
            memberCard: medicalBenefitsComponentConfig.memberCard,
            planBenefits: medicalBenefitsComponentConfig.planBenefits,
            planDropdownConfig: medicalBenefitsComponentConfig.planDropdownConfig,
            periodDropdownConfig: medicalBenefitsComponentConfig.periodDropdownConfig,
            customMessageHtml: medicalBenefitsComponentConfig.customMessageHtml,
        };
    }

    toValidConfig(pageConfigInput: any, defaultValidconfig?: any): any {
        type Key = string;
        type Keys = Key[];
        let validConfigKeys: Keys;

        let pageConfig = cloneObject(pageConfigInput) || {};
        const pageConfigKeys: Keys = Object.keys(pageConfig);
        // The default config is an instance of a valid config.
        defaultValidconfig ? (validConfigKeys = Object.keys(defaultValidconfig)) : (validConfigKeys = Object.keys(defaultConfig));
        const missingConfigKeys: Keys = validConfigKeys.filter((validConfigKey: Key) => !pageConfigKeys.includes(validConfigKey));
        pageConfig = this.applyDefaultConfig(pageConfig, missingConfigKeys, defaultValidconfig || defaultConfig);

        return pageConfig;
    }

    applyDefaultConfig(pageConfig: any, missingConfigKeys: string[], defaultValidconfig: any) {
        missingConfigKeys.forEach((missingConfigKey: string) => (pageConfig[missingConfigKey] = defaultValidconfig[missingConfigKey]));

        return pageConfig;
    }
}
