import { DatePipe, CurrencyPipe } from '@angular/common';
import { ActiveInactivePipe } from './active-inactive-pipe/active-inactive-pipe';
import { DateYearSwapperPipe } from './date-year-swapper/date-year-swapper.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SubscriberFullNamePipe } from './subscriber-full-name-pipe/subscriber-full-name.pipe';
import { HashmapValuesPipe } from './hash-map-pipe/hash-map.pipe';
import { StringBuilderReplacementPipe } from './string-builder-replacement-pipe/string-builder-replacement.pipe';
import { HiddenNumberPipe } from './hidden-number-pipe/hidden-number.pipe';
import { ValueGetterPipe } from './value-getter-pipe/value-getter.pipe';
import { AddressDisplayPipe } from './address-display-pipe/address-display.pipe';
import { MonthPipe } from './month.pipe';
import { FullNamePipe } from './full-name.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { SnakeToTitlePipe } from './snake-to-title.pipe';
import { UTCShortDatePipe } from './utc-short-date.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { ZipAppDisplayPipe } from './zip-app-display.pipe';
import { NamePipe } from './name.pipe';
import { DashedDatePipe } from './short-date.pipe';
import { TimeDatePipe } from './time-date.pipe';
import { LastNameFirstNamePipe } from './last-name-first-name-pipe';

export const pipeTypeMap = {
    DatePipe,
    ActiveInactivePipe,
    DateYearSwapperPipe,
    MonthPipe,
    PhoneNumberPipe,
    SnakeToTitlePipe,
    UTCShortDatePipe,
    YesNoPipe,
    FullNamePipe,
    NamePipe,
    LastNameFirstNamePipe,
    SafeHtmlPipe,
    SubscriberFullNamePipe,
    HashmapValuesPipe,
    HiddenNumberPipe,
    StringBuilderReplacementPipe,
    ZipAppDisplayPipe,
    ValueGetterPipe,
    AddressDisplayPipe,
    CurrencyPipe,
    DashedDatePipe,
    TimeDatePipe,
};
