import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/Observable';
import { getValue } from '../shared/zipui-shared-module/value-getter';
import { Name } from '../member-portal/modules/shared/services/member.service.types';
import { AppConfig } from '../app.config';
import { Configs } from './config.service';
import { ActualUser, ApiError, AppUserData, AuthServiceOptions, ImpersonatedUser, Role, User } from './auth.service.types';
import { VALID_ROLES } from './constants/user-roles';
import { Path } from './types/url.type';
import { exists } from './utilities/Exists';
import { httpResponseCode } from './utilities/Http';

/** Auth and user service
 *
 * Compare this to `MemberService`. This is like a service for handling the user as a Zipari internal. The member service is more like how
 * the user appears as a member in one of the health plans. This service is used for authentication, but that is not its whole scope. */
@Injectable()
export class AuthService {
    public actualUser: ActualUser;
    public authServiceOptions: AuthServiceOptions;
    public impersonatedUser: ImpersonatedUser;
    public redirect_path: string;
    public showNav: boolean;
    public user: User;
    public resetPasswordToken: string;
    public didUserJustRegister = false;
    public userApi$: BehaviorSubject<AppUserData> = new BehaviorSubject(null);
    private appConfig: AppConfig;
    private appUserData$: BehaviorSubject<AppUserData> = new BehaviorSubject(null);
    private readonly forgotPasswordUrl: Path = 'api/user/forgot_password/';
    private readonly forgotUsernameUrl: Path = 'user/forgot_username/';
    private readonly resetPasswordUrl: Path = 'user/reset_password/';

    constructor(private http: HttpClient) {}

    setResetPasswordToken(token: string) {
        this.resetPasswordToken = token;
    }

    /** The logged in user */
    get loggedInUser(): ActualUser | ImpersonatedUser {
        return this.impersonatedUser ? this.impersonatedUser : this.actualUser;
    }

    /** The "actual user" is also set here. Config always exists and `userDataSource` is never a config key. */
    set loggedInUser(user: ActualUser | ImpersonatedUser) {
        const memberPortalConfig: any = this.appConfig.global;
        this.authServiceOptions = memberPortalConfig ? memberPortalConfig.auth_options : ({} as AuthServiceOptions);
        if (user) this.actualUser = { ...this.actualUser, app_user_data: user, roles: user.roles };
        this.user = user;
    }

    /** The name of the current user's role */
    public get userRole(): Name {
        if (this.noLoggedInUser(this.loggedInUser) || this.loggedInUserHasNoRoles(this.loggedInUser)) return 'Anonymous';
        else if (this.loggedInUserHasActiveRole(this.loggedInUser)) {
            return this.loggedInUser.app_user_data.user_role
                ? this.loggedInUser.app_user_data.user_role
                : this.loggedInUser.app_user_data.data.active_role;
        } else return this.firstValidRole(this.loggedInUser);
    }
    noLoggedInUser(loggedInUser: ActualUser | ImpersonatedUser): boolean {
        return !loggedInUser;
    }
    loggedInUserHasNoRoles(loggedInUser: ActualUser | ImpersonatedUser): boolean {
        return loggedInUser && loggedInUser.roles && loggedInUser.roles.length === 0;
    }
    loggedInUserHasActiveRole(loggedInUser: ActualUser | ImpersonatedUser): boolean {
        return (
            exists(loggedInUser.app_user_data) &&
            exists(loggedInUser.app_user_data.data) &&
            exists(loggedInUser.app_user_data.data.active_role)
        );
    }
    firstValidRole(loggedInUser: ActualUser | ImpersonatedUser): Name {
        // the name of the 'Admin' role could vary by tenant
        const customRoles = this.appConfig.global?.valid_roles ?? [];
        const validRole: Role = loggedInUser.roles.find((role: Role) => [...customRoles, ...VALID_ROLES].includes(role.name));

        if (!validRole) throw new Error('No valid roles for logged in user');
        else return validRole.name;
    }

    /** Whether multiple roles are allowed or not */
    public get dualRolesFunctionality(): boolean {
        return this.noAuthServiceOptions ? false : !this.authServiceOptions.disable_multi_roles; // Should be true
    }
    noAuthServiceOptions(): boolean {
        return !this.authServiceOptions || Object.keys(this.authServiceOptions).length === 0;
    }

    getUser(): Promise<User> {
        return this.getUserFromApi();
    }

    /** The app user from the user API */
    getUserFromApi(): Promise<User> {
        return new Promise((resolve: Function, reject: Function): void => {
            this.http
                .get('api/user/')
                .toPromise()
                // `appUserData` is maybe the least descriptive possible name for this API response possible in the context of this app.
                .then((appUserData: AppUserData) => {
                    this.userApi$.next(appUserData);
                    resolve(appUserData);
                })
                .catch((error: ApiError) => {
                    delete this.actualUser;
                    delete this.user;

                    return error.status === httpResponseCode['unauthorized'] || httpResponseCode['forbidden'] ? resolve() : reject(error);
                });
        });
    }

    /** Takes an app config that is not exactly what is defined in tenant configs. */
    setAppConfig(config: Configs): unknown {
        this.appConfig = config.APP;

        return config;
    }

    login(credentials: { username: string; password: string }): Observable<AppUserData> {
        return this.http
            .post<AppUserData>(this.loginPath(this.appConfig), credentials)
            .pipe(tap((appUserData: AppUserData) => this.appUserData$.next(appUserData)));
    }

    /** TODO(cstroh): Does login key exist on any app config? */
    loginPath(appConfig: unknown): string {
        return getValue(appConfig, 'login.loginEndpoint') || '/login/';
    }

    register(userRegistration: unknown): Observable<unknown> {
        return this.http.post('/user/register/', userRegistration);
    }

    /** An observable of the post request to the reset password API */
    resetPassword(credentials: object): Observable<unknown> {
        return this.http.post<string>(this.resetPasswordUrl, credentials);
    }

    sendForgotPasswordEmail(payload: object): Observable<unknown> {
        return this.http.post<string>(this.forgotPasswordUrl, payload);
    }

    forgotUsername(email: string): Observable<string> {
        return this.http.post<string>(this.forgotUsernameUrl, { email_address: email });
    }
}
