import { getValue } from './value-getter';

export function stringBuilder(template, context) {
    let enhancedString = template;
    const templateMatcher = /(\${)([a-zA-Z0-9\/._]+)(})/g;
    const matches = template.match(templateMatcher);
    const idx = 2;

    if (matches) {
        matches.forEach((match) => {
            const basePropRegex = /(^[a-zA-Z\/_]+)(\.)(.+)/g;
            const [value, base, __, prop, ___] = match.split(templateMatcher)[idx].split(basePropRegex);
            const newValue = prop ? getValue(context[base], prop) : context[value];
            enhancedString = enhancedString.replace(match, newValue === undefined ? '' : newValue);
        });
    }

    return enhancedString;
}
