import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestCacheService } from './request-cache.service';

@Injectable()
export class CacheRequestResponseInterceptor implements HttpInterceptor {
    static SKIP_CACHE_KEY = 'skip-request-cache';
    allRequestUrls = {};
    constructor(private cache: RequestCacheService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const body = JSON.stringify(req.body || '');
        const cachedResponse = req.headers.has(CacheRequestResponseInterceptor.SKIP_CACHE_KEY)
            ? null
            : this.cache.get(req.urlWithParams + body);

        return !cachedResponse
            ? next.handle(req).pipe(
                  tap((evt) => {
                      if (evt instanceof HttpResponse) {
                          this.cache.put(req.urlWithParams + body, evt.clone());
                      }
                  }),
              )
            : // asyncScheduler is here to ensure the synchronous nature on the 'of' function
              // does not cause unsubscribe errors in our finally blocks
              of(cachedResponse.clone(), asyncScheduler);
    }
}

export const CACHE_REPONSE_PROVIDER: any = {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheRequestResponseInterceptor,
    multi: true,
};
