import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { CanActivateProductRouteGuard } from './shared/guards/product-route.guard';
const defaultRoute: string = 'member-portal';

export const configuredRoutes: Route[] = [
    {
        path: 'member-portal',
        loadChildren: () => import('./member-portal/member-portal.module').then((m) => m.MemberPortalModule),
        data: { pageName: 'global', indexRoute: 'member-portal' },
        canActivate: [CanActivateProductRouteGuard],
    },
    {
        path: '**',
        redirectTo: defaultRoute,
        pathMatch: 'full',
    },
];

/**
 * Top level routing module
 */
@NgModule({
    imports: [RouterModule.forRoot(configuredRoutes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
