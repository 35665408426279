import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appDisplay',
})
export class ZipAppDisplayPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        let finalStr;

        if (!args) {
            args = {};
        }

        switch (value) {
            // todo if we want to add more we can
            case 'zshop':
                finalStr = args.zshop ? args.zshop : 'Shopping Portal';
                break;
            case 'broker-portal':
                finalStr = args['broker-portal'] ? args['broker-portal'] : 'Broker Portal';
                break;
            default:
                finalStr = value;
                break;
        }

        return finalStr;
    }
}
