import { Injectable } from '@angular/core';
import * as cx from 'zipari-cx/dist';
import { getValue } from './../zipui-shared-module/value-getter';

@Injectable({
    providedIn: 'root',
})
export class CxService {
    private caller: cx.CXCaller;
    private calls: { [key: string]: any };

    initialize(config: cx.CxCallerConfig, calls: { [key: string]: any }) {
        this.caller = new cx.CXCaller(config);
        this.calls = calls;
    }

    /**
     * This is necessary so we can configure unique key names for configuring each event
     * event separately, but some actual call names are non-unique. This is a simple,
     * configurable override of the call name
     */
    makeCall(callKey: string, event?: any) {
        const config = getValue(this.calls, callKey);
        const eventKey = getValue(config, 'event_key');
    }
}
