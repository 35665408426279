import { AbstractControl, FormGroup } from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

/**
 * Conditional require function.
 */

function optionalRequired(form: FormGroup, dependencies: any) {
    return function (input: AbstractControl) {
        if (isEmptyInputValue(input.value)) {
            for (const dependency of dependencies) {
                if (!isEmptyInputValue(form.root.get(dependency).value)) {
                    return { required: true };
                }
            }
        }
    };
}

function equals_value(target: any) {
    return function (input: AbstractControl) {
        return input.value === target.value
            ? {
                  equals_value: {
                      message: target.hint,
                  },
              }
            : null;
    };
}

/**
 * Same as
 */
function sameAs(form: FormGroup, target: any) {
    // Yikes -- mem leak for sure. This is so that the field updates again when
    // the target gets updated.
    // Need to find a better way to track this subscription so that it can be unsub to.
    const targetProp = form.get(target.prop);
    if (targetProp) {
        targetProp.valueChanges.subscribe(() => {
            form.root.get(target.self).updateValueAndValidity({ onlySelf: true });
        });
    }

    return function (input: AbstractControl) {
        if (input.value) {
            return input.value === form.get(target.prop).value
                ? null
                : {
                      sameAs: {
                          message: target.hint,
                      },
                  };
        }

        return null;
    };
}

/**
 * Different From Description
 */
function differentFrom(form: FormGroup, target: any) {
    // Yikes -- mem leak for sure. This is so that the field updates again when
    // the target gets updated.
    // Need to find a better way to track this subscription so that it can be unsub to.
    const targetProp = form.get(target.prop);
    if (targetProp) {
        targetProp.valueChanges.subscribe(() => {
            if (form.get(target.self)) {
                form.get(target.self).updateValueAndValidity({
                    onlySelf: true,
                });
            }
        });
    }

    return function (input: AbstractControl) {
        if (input.value) {
            return input.value !== form.get(target.prop).value
                ? null
                : {
                      differentFrom: {
                          message: target.hint,
                      },
                  };
        }

        return null;
    };
}

// This is another copy of different from field. i.e. if we don't want to allow password same as last name and firstname then we need another rule.

function differentFrom2(form: FormGroup, target: any) {
    const targetProp = form.get(target.prop);
    if (targetProp) {
        targetProp.valueChanges.subscribe(() => {
            if (form.get(target.self)) {
                form.get(target.self).updateValueAndValidity({
                    onlySelf: true,
                });
            }
        });
    }

    return function (input: AbstractControl) {
        if (input.value) {
            return input.value !== form.get(target.prop).value
                ? null
                : {
                      differentFrom2: {
                          message: target.hint,
                      },
                  };
        }

        return null;
    };
}

function decimalPlaceValidator(decimalPlace: number) {
    const regex = new RegExp(`^(?=.*\\d)(\\d|\\-|\\+)*(?:\\.\\d{0,${decimalPlace}})?$`);

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }

        const valid = regex.test(input.value);

        return valid ? null : { decimal: true };
    };
}

function emailPattern() {
    const regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }

        const valid = regex.test(input.value);

        return valid ? null : { email: true };
    };
}

function upperCase() {
    const regex = new RegExp('(?=.*[A-Z])');

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { hasUpperCase: true };
    };
}

function lowerCase() {
    const regex = new RegExp('(?=.*[a-z])');

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { hasLowerCase: true };
    };
}

function integer() {
    const regex = new RegExp('(?=.*\\d)');

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { hasInteger: true };
    };
}

function specialChar() {
    const regex = new RegExp(/(?=.*?[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/);

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { hasSpecChar: true };
    };
}

function password() {
    // const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&\'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$');
    const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\\-_]).{8,}$');

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { password: true };
    };
}

function numberOnly() {
    const regex = new RegExp('^[0-9]*$');

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { numOnly: true };
    };
}

function fourDigitsOnly() {
    const regex = new RegExp(/^\d{4}$/);

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const valid = regex.test(input.value);

        return valid ? null : { fourDigitsOnly: true };
    };
}

function isCurrency() {
    const regex = new RegExp(/^[0-9]+(\.)?([0-9]{1,2})?$/);

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }

        const valid = regex.test(input.value);

        return valid ? null : { isCurrency: true };
    };
}

function phonePattern(numberType: string) {
    const regex = new RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/);

    const PHONE_ERROR = numberType === 'phone' ? { phone: true } : { fax: true };

    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }

        const valid = regex.test(input.value);

        return valid ? null : PHONE_ERROR;
    };
}

function dateRange(form: FormGroup, before?: any, after?: any) {
    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }
        const inputDate = new Date(input.value);
        const minimumDate = new Date('1700/01/01');
        if (inputDate < minimumDate) {
            return {
                date: {
                    message: 'Please Enter A Valid Date',
                },
            };
        }

        if (before) {
            const beforeDate = new Date(form.get(before.prop).value);
            if (before.year) {
                beforeDate.setFullYear(beforeDate.getFullYear() + before.year);
            }

            const valid = inputDate <= beforeDate;

            return valid
                ? null
                : {
                      date: {
                          message: before.hint,
                      },
                  };
        } else if (after) {
            const afterDate = new Date(form.get(before.prop).value);
            if (before.year) {
                afterDate.setFullYear(afterDate.getFullYear() + before.year);
            }

            const valid = inputDate <= afterDate;

            return valid
                ? null
                : {
                      date: {
                          message: after.hint,
                      },
                  };
        }

        return null;
    };
}

/**
 * Check if input value is in increment of given number
 */
function numberIncrementPattern(increment: { value: number; hint: string }) {
    return function (input: AbstractControl) {
        if (!input.value) {
            return null;
        }

        const valid = Number.isInteger(input.value / increment.value);

        return valid
            ? null
            : {
                  increment: {
                      message: increment.hint,
                  },
              };
    };
}

export default {
    equals_value,
    optionalRequired,
    sameAs,
    emailPattern,
    phonePattern,
    dateRange,
    numberIncrementPattern,
    decimalPlaceValidator,
    differentFrom,
    differentFrom2,
    upperCase,
    lowerCase,
    integer,
    specialChar,
    password,
    numberOnly,
    isCurrency,
    fourDigitsOnly,
};
