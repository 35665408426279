import { Name } from './../../api/models/name';

export function nameConcat(name: Name): string {
    if (!name) return '';
    const concatenatedName = `${name['prefix'] || ''} ${name['first_name'] || ''} ${name['middle_name'] || ''} ${name['last_name'] || ''} ${
        name['suffix'] || ''
    }`;

    return concatenatedName.replace(/\s+/g, ' ').trim();
}
