import { PrimaryCareProvider } from '../../benefits/medical/medical.types';

export interface ApiResultsArray {
    count: number;
    next: number;
    previous: number;
    results: any[];
}

export interface BenefitsConfig {
    api: unknown;
    useBenefit_category_display_text: boolean;
    showDuplicateBenefits: boolean;
}

export interface ClaimsConfig {
    api: any;
}

/** `addresses` and `address` are incompatible. The settings component needs `address`. Member details in member service needs `addresses.
 */
export class MemberDetails {
    address: Address;
    addresses: Address[];
    birth_date: string;
    cell_phone: string;
    created_at: string;
    crm_id: string;
    dhs_number: string;
    disabled: boolean;
    effective_date: unknown;
    email_address: string;
    email: string;
    employer_name: string;
    ethnicity: string;
    ffe_assigned_member_id: string;
    first_name: string;
    gender: string;
    has_disability: boolean | string;
    height: string;
    home_phone: string;
    id: string;
    info_method: string;
    issuer_assigned_member_id: string;
    last_date_of_tobacco_use: unknown;
    last_name: string;
    marital_status: string;
    /** An identifier separate from the member's database primary key that is appropriate to show back to the member. */
    member_display_identifier: string;
    member_id: string;
    member_name: string;
    member_number: string;
    middle_name: string;
    name: MemberName;
    office_phone: string;
    pcp_list: PrimaryCareProvider[];
    prefix_name: string;
    primary_language: string;
    primary_phone_type: string;
    privacy_restriction: boolean;
    relationship_to_subscriber: string;
    secondary_language: string;
    ssn: string;
    subscriber_number: string;
    suffix_name: string;
    tenant: string;
    terminated_date: unknown;
    termination_date: unknown;
    tobacco_use: boolean;
    tribal_id: string;
    updated_at: string;
    weight: string;
}
export interface Address {
    city_name: string;
    lat: number;
    lng: number;
    state: string;
    street_name_1: string;
    street_name_2: string;
    street_name_3: string;
    zip_code: string;
    address_type: 'physical' | 'mailing';
}

export type MemberPlanSummary = unknown;

export type MemberBenefits = unknown;

export type MemberBenefitPeriods = unknown;

export interface MemberClaim {
    claim_number: number;
}

export type MemberClaims = MemberClaim[];

export type Name = string;

export interface MemberName {
    first_name: string;
    last_name: string;
    middle_name: string;
    prefix: string;
    suffix: string;
}
export interface Option {
    id: number;
    short_name: string;
    detail_text: string;
    priority_number: number;
    select_many: boolean;
}

export interface Preference {
    id: number;
    preference_group: string;
    preference_types: PreferenceTypes[];
    preference_options: Option[];
    display_text: string;
}

export interface AllPreferences extends Preference {
    preferences: Preference[];
}

export interface PreferenceTypes {
    id: number;
    short_name: string;
    detail_text: string;
    priority_number: number;
    user_selected_option_ids: number[];
    available_option_ids: number[];
}

export interface UserPreferences extends Preference {
    data: Preference[];
}

export type CommunicationPreferences = [AllPreferences, UserPreferences];

export interface Choice {
    name: string;
}

export interface Preferences {
    choices: Choice[];
}

export interface Benefit {
    benefit_applies_to: string;
    benefit_category: string;
    benefit_category_display_text: string;
    benefit_service_id: string;
    benefit_unit_max: string;
    benefit_unit_type: string;
    benefit_unit_value: string;
    co_insurance: string;
    co_pay_pcp: string;
    co_pay_specialist: string;
    co_payment: string;
    created_at: string;
    deductible: string;
    effective_date: string;
    id: number;
    in_network: unknown;
    limitation_display: string;
    member_coverage: string;
    out_of_network: unknown;
    out_of_pocket: string;
    participation_level: string;
    plan: number;
    prior_auth_required: boolean;
    priority_weighting: number;
    referral_required: boolean;
    tenant: string;
    termination_date: string;
    updated_at: string;
}

export interface MemberDetail {
    id: 1;
    effective_date: unknown;
    last_date_of_tobacco_use: unknown;
    weight: unknown;
    ethnicity: unknown;
    employer_name: unknown;
    info_method: unknown;
    created_at: string;
    member_name: string;
    privacy_restriction: unknown;
    prefix_name: unknown;
    tenant: string;
    birth_date: string;
    tobacco_use: unknown;
    first_name: string;
    dhs_number: unknown;
    last_name: string;
    height: unknown;
    home_phone: string;
    terminated_date: string;
    office_phone: unknown;
    cell_phone: unknown;
    termination_date: unknown;
    email_address: unknown;
    marital_status: string;
    name: {
        middle_name: unknown;
        first_name: string;
        prefix: unknown;
        suffix: unknown;
        last_name: string;
    };
    member_number: string;
    secondary_language: unknown;
    issuer_assigned_member_id: unknown;
    email: string;
    suffix_name: unknown;
    tribal_id: unknown;
    gender: string;
    primary_phone_type: unknown;
    ffe_assigned_member_id: unknown;
    crm_id: unknown;
    middle_name: unknown;
    updated_at: string;
    disabled: unknown;
    ssn: string;
    addresses: [
        {
            id: number;
            effective_date: unknown;
            attention: unknown;
            county_name: unknown;
            state: string;
            zip_code: string;
            type: string;
            created_at: string;
            city_name: string;
            tenant: string;
            street_name_1: string;
            county_code: unknown;
            updated_at: string;
            street_name_2: unknown;
            title: unknown;
            address_type: string;
            street_name_3: unknown;
            termination_date: unknown;
        },
    ];
    primary_language: unknown;
}

export interface AllMembers extends ApiResultsArray {
    results: MemberDetails[];
}

export interface AllMemberQueryParams {
    first_name?: string;
    last_name?: string;
    member_number?: string;
    date_of_birth?: string;
    phone_number?: string;
    market_segment?: string;
    line_of_business?: string;
}
