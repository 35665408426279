/* tslint:disable */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { stringBuilder } from '../../shared/zipui-shared-module/string-builder';
import { cloneObject } from '../zipui-shared-module/object-utils';

import { Undetermined } from '../utilities/Undetermined';
import { ConfigService } from './../config.service';

declare const utag: any;
declare const zga: any;
declare const gtag: any;

interface GlobalConfig {
    GoogleAnalytics: Undetermined;
}

export enum validGAEvents {
    'workflow_step' = 'workflow_step',
    'plan-details_viewed' = 'plan-details_viewed',
    'plan_added-to-cart' = 'plan_added-to-cart',
    'plan_removed-from-cart' = 'plan_removed-from-cart',
    'contact-us_viewed' = 'contact-us_viewed',
    'plans_compared' = 'plans_compared',
    'application_printed' = 'application_printed',
}

export class GAEventOptions {
    eventCategory: string;
    eventAction: string;
    eventLabel: string = null;
    eventValue: any = null;
    hitType = 'event';

    constructor(options) {
        if (options) {
            const newOptions = cloneObject(options);
            if (newOptions.eventLabel) {
                this.eventLabel = newOptions.eventLabel;
            } else {
                console.warn('No key provided for GA');
            }

            if (newOptions.dictionary_attributes.eventCategory) {
                this.eventCategory = newOptions.dictionary_attributes.eventCategory;
                delete newOptions.dictionary_attributes.eventCategory;
            } else {
                this.eventCategory = this.eventLabel;
            }

            if (newOptions.dictionary_attributes.eventAction) {
                this.eventAction = newOptions.dictionary_attributes.eventAction;
                delete newOptions.dictionary_attributes.eventAction;
            } else {
                this.eventAction = this.eventLabel;
            }

            this.eventValue = 0;
        } else {
            console.warn('No options provided for GA call');
        }
    }
}

@Injectable()
export class GoogleAnalyticsService {
    configuration;
    gaIsConfigured = false;

    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private configService: ConfigService) {
        this.configuration = this.configService.getPageConfig<GlobalConfig>('global').GoogleAnalytics;

        if (this.configuration && this.configuration.gaIsConfigured) {
            this.gaIsConfigured = this.configuration.gaIsConfigured;
            if (this.configuration.onRoute) {
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd) {
                        this.onEvent(this.configuration.onRoute, event);
                    }
                });
            }
        }
    }

    public emitGAEvent(key: validGAEvents, dictionary_attributes: any = {}) {
        if (typeof gtag !== 'undefined') {
            gtag('event', key, dictionary_attributes);
        }
    }

    get zga(): any {
        if (utag || zga) {
            return utag ? utag : zga;
        } else {
            return {};
        }
    }

    /**
     * Method that handles the actual sending of data to the Google Analytics script on the page
     */
    sendUp(data: any) {
        if (this.gaIsConfigured) {
            this.zga.view(data);
        }
    }

    /**
     * Method that grabs basic configurable fields set in a GoogleAnalyticsEventProp from an event
     *      to send up via the Google Analytics script on the page
     * @param eventType Array of GoogleAnalyticsEventProps
     * @param event A Google Analytics event that contains the data from which the data sent up is pulled from
     */
    onEvent(eventType: GoogleAnalyticsEventProp[], event) {
        if (!this.gaIsConfigured) {
            return;
        }

        const data = {};
        const context = { page: this._getPageData(this.route.snapshot), route: event };
        eventType.forEach((prop: any) => {
            data[prop.name] = stringBuilder(prop.value, context);
        });
        this.sendUp(data);
    }

    private _getPageData(routeSnapshot) {
        let child = routeSnapshot.firstChild;
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
            } else {
                return child.data;
            }
        }
    }
}

/**
 * Type that represents the properties we allow for a Google Analytics event
 */
class GoogleAnalyticsEventProp {
    // Name of the property
    name: string;
    // Text that comes before the field, a static value that helps describe the field which comes after
    staticBeforeText: string;
    // Text that comes after the field, a static value that helps describe the field which came before
    staticAfterText: string;
    // Field on the event to be passed to GoogleAnalyticsService.sendUp(event) to provide as part of the data sent up
    field: string;
}
