import { Inject, Injectable } from '@angular/core';
import { MenuItem } from '../../../member-portal/modules/navigation/navigation.component';
import { ConfigService } from '../../config.service';
import { WINDOW } from '../../utilities/Window';
import { languageMapping } from './language.constant';

interface MotionPointConfig {
    url: string;
    language: string;
}
@Injectable()
export class LanguagePreferenceService {
    private config;
    private motionPointConfig: MotionPointConfig;

    constructor(private configService: ConfigService, @Inject(WINDOW) private window: Window) {
        this.config = this.configService.getPageConfig('settings');
        if (this.config.language_preference && this.config.language_preference.motionPoint) {
            this.motionPointConfig = this.config.language_preference.motionPoint;
        }
        if (this.window['MP'] && this.motionPointConfig) {
            const MP = this.window['MP'];
            MP.UrlLang = 'mp_js_current_lang';
            MP.SrcUrl = decodeURIComponent('mp_js_orgin_url');
            MP.oSite = decodeURIComponent('mp_js_origin_baseUrl');
            MP.tSite = decodeURIComponent('mp_js_translated_baseUrl');
            MP.init();
        }
    }

    /** TODO(jduong): Pass in a language shorthand. */
    setLanguagePreference(menuItem: MenuItem): void {
        if (menuItem.route) this.window.open(menuItem.route, menuItem.isExternal ? '_blank' : '_self');
        if (menuItem.label === languageMapping['spa'] && this.motionPointConfig) this.initMotionPoint();
    }

    initMotionPoint(): void {
        const MP = this.window['MP'];
        MP.init();
        let url = this.motionPointConfig.url;
        const language = this.motionPointConfig.language;
        const tSite = MP.tSite.replace(/(https?:\/\/|\/?$)/g, '');
        url = url.replace(/(https?:\/\/|\/?$)/g, '');
        MP.switchLanguage(tSite.search(url) !== -1 ? MP.oSite : url, language, true);
    }
}
