import { Pipe, PipeTransform } from '@angular/core';
import { stringBuilder } from '../../../shared/zipui-shared-module/string-builder';

@Pipe({
    name: 'buildString',
    pure: false,
})
export class StringBuilderReplacementPipe implements PipeTransform {
    transform(string: any, given_context: any[]): any {
        if (!string) {
            return '';
        }

        if (!given_context || (given_context && given_context.length === 0)) {
            return string;
        }

        const context = {};
        given_context.forEach((contextItem) => {
            Object.assign(context, contextItem);
        });

        const result = stringBuilder(string, context);

        return result ? result.trim() : '';
    }

    pipeServiceTransform(context: any, string: any) {
        return stringBuilder(string, context);
    }
}
