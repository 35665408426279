// handles getting a clone of an object that goes recursively down the structure and is faster than JSON.parse and JSON.stringify
// also dynamically handles array objects as well
export function isObj(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * Arrays in target are overwritten by source
 * @param target
 * @param src
 */
export function deepMerge(target: object, src: object): object {
    if (!target || !isObj(target)) return src;
    let output: object = { ...target };
    if (isObj(target) && isObj(src)) {
        Object.keys(src).forEach((key: string) => {
            !isObj(src[key]) || !(key in target)
                ? (output = { ...output, [key]: src[key] })
                : (output[key] = deepMerge(target[key], src[key]));
        });
    }

    return output;
}

export function cloneObject(obj) {
    let clone;
    if (Array.isArray(obj)) {
        clone = obj.map((individualObj) => cloneObject(individualObj));
    } else {
        if (typeof obj === 'object') {
            clone = {};
            for (const i in obj) {
                if (obj[i] && typeof obj[i] === 'object') {
                    clone[i] = cloneObject(obj[i]);
                } else {
                    clone[i] = obj[i];
                }
            }
        } else {
            return obj;
        }
    }

    return clone;
}

// handles getting a clone of an object that goes recursively down the structure and is faster than JSON.parse and JSON.stringify
// also dynamically handles array objects as well
export function deepCompare(obj, obj2, ignoreProps?) {
    if (!obj || !obj2 || typeof obj !== 'object' || typeof obj2 !== 'object') {
        return false;
    }
    if (ignoreProps) {
        obj = cloneObject(obj);
        obj2 = cloneObject(obj2);
        for (const key in ignoreProps) {
            delete obj[key];
            delete obj2[key];
        }
    }

    return JSON.stringify(obj) === JSON.stringify(obj2);
}

export function recursivelyRunCallbackOnEntireObj(obj, stack, context, ignoreProp = null, callback) {
    for (const property in obj) {
        if (property !== ignoreProp) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === 'object') {
                    obj[property] = recursivelyRunCallbackOnEntireObj(obj[property], `${stack}.${property}`, context, ignoreProp, callback);
                } else {
                    callback(obj, property, context);
                }
            }
        }
    }

    return obj;
}

export function deepMergeIncludingNulls(target: Object, src: Object): Object {
    if (!target) {
        return src;
    }
    if (!isObj(target)) {
        return src;
    }

    let output = { ...target };
    if (isObj(target) && isObj(src)) {
        const keys = Object.keys(src);
        keys.forEach((key) => {
            if (!isObj(src[key]) || !(key in target)) {
                if (src[key] === null) {
                    output[key] = null;
                } else {
                    output = { ...output, [key]: src[key] };
                }
            } else {
                output[key] = deepMerge(target[key], src[key]);
            }
        });
    }

    return output;
}
