import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { ConfigService } from './../config.service';
import { LoggerService } from './logger.service';

export const noPolicyIsCurrentlySelected = '-1';

/**
 * A policy selection
 */
export interface CurrentPolicy {
    id: string;
}

/**
 * Service for tracking the currently selected policy.
 *
 * This is only applicable for clients with users who *can* have multiple policies,
 * and most do not. This is communicated to the FE through the configured prop
 * "canHaveMultiplePolicies". New values should only be emitted by the policy
 * selection dropdown, located at the top of the page next to "profile and settings",
 * and the messages and notifications icons.
 *
 * THANKS(jwang):
 *   Multi-policy should affect 6 components:
 *     Document center
 *     Accumulators
 *     Authorizations
 *     Claims
 *     ID card
 *     Plan benefits
 *
 * @export
 * @class CurrentPolicyService
 * @member currentPolicy Behavior subject that contains the currently selected policy
 */
@Injectable()
export class CurrentPolicyService {
    public canHaveMultiplePolicies = false;
    selectedPolicyUrl = '/api/member-portal/selected-policy/';
    /**
     * Behavior subject that contains the currently selected policy
     *
     * @type {BehaviorSubject<CurrentPolicy>}
     * @memberof CurrentPolicyService
     */
    currentPolicy$: BehaviorSubject<CurrentPolicy>;

    /** Use to emit value when policy is set
     *
     * THANKS(jwang):
     *   This is triggered when policy drop down in the nav bar is selected. The nav bar is universal for the whole app, it is unique and
     *   only created once, so no need to destroy this `Subject`. If you remove this subject from a component, that component will no
     *   longer support the multiple policies feature. Selecting different policies uses this subject to update policies accordingly.
     */
    isPolicySet$: Subject<boolean> = new Subject();

    constructor(private http: HttpClient, private configService: ConfigService, private logger: LoggerService) {
        this.canHaveMultiplePolicies = this.configService.getPageConfig('global')['canHaveMultiplePolicies'] ?? false;
        /**
         * Always at least create the currentPolicy behavior subject so nothing breaks when trying to subscribe.
         *
         * Users should assume that a value of '-1' means to not use this value in their HTTP calls,
         * as -1 is assumed to be physically impossible for a policy ID
         */
        this.currentPolicy$ = new BehaviorSubject({ id: noPolicyIsCurrentlySelected });
    }

    /** Post the selected policy to the back-end */
    postCurrentPolicy(policy: CurrentPolicy): void {
        this.http.post(this.selectedPolicyUrl, policy, { headers: { 'skip-request-cache': 'config' } }).subscribe(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => this.setCurrentPolicy(policy),
            (error: unknown) => {
                this.logger.consoleError(error);
            },
        );
    }

    /**
     * Sets the current policy ID to the value given
     *
     * @param id the new policy ID to use in API calls
     */
    setCurrentPolicy(policy: CurrentPolicy): void {
        this.currentPolicy$.next(policy);
    }

    /**
     * Gets the current policy as a CurrentPolicy observable, then completes.
     * It does not affect components that are using currentPolicy$ directly.
     * It is only used in PlanBenefitsService.
     * Combining with policySet$.unsubscribe() in each plan benefit sub tab component,
     * it solves the plan benefits memory leaks issue.
     */
    getCurrentPolicy(): Observable<CurrentPolicy> {
        return of(this.currentPolicy$.value);
    }

    /**
     * Retrieves the default policy from the server and updates the behavior subject with the result
     */
    getDefaultPolicy(): void {
        this.http
            .get(this.selectedPolicyUrl)
            .subscribe((defaultPolicyIDResponse: CurrentPolicy) => this.currentPolicy$.next(defaultPolicyIDResponse));
    }

    emitSetPolicyStatus(isSet: boolean): void {
        this.isPolicySet$.next(isSet);
    }
}
