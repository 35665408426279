import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Modal2Config, ModalActions } from './zip-modal2.model';

/** Service that handles the modal's current state. It includes two observables, one for interacting with the modal, and another for
 * emitting events to the outside world. Usage assumes that the client of this modal component will listen to the modal state and tell the
 * modal when to close. We do this so that the modal does not have to worry about handling its own closed state. This allows us to easily
 * handle submitted states that wait for an API call to be made. Note that the modal can tell itself to close, as the modal itself emits an
 * event to the container which will call closeModal(). Clients should only listen to events with the modalIdentifier that they set on
 * creation of their modal.

 * NOTE: a client component's modal will close unexpectedly if another component opens a modal. This must be anticipated. This is
 * unlikely to happen as, theoretically speaking, a modal should close before any component that might open a second modal can be
 * interacted with. */
@Injectable()
export class ZipModal2Service {
    /** Observable that gives updated config and status to the observable itself */
    modalEventsObserver$: Subject<unknown> = new Subject<unknown>();

    /** Observable used by external components to watch the modal for updates and emitted data */
    modalReturnedData$: Subject<unknown> = new Subject<unknown>();

    /**
     * Open a modal with the given config and optional data
     * @param config defines how the modal will display
     * @param context that the modal will display with
     *
     * Note: Calling this method for an already open modal is *BAD*:
     * It will almost certainly cause a rerender of the modal, which will likely look/act strangely.
     * If you want to update the modal, use updateModalState() or updateModalContext() instead
     */
    openModal(config: Modal2Config, context?: unknown): Observable<any> {
        this.modalEventsObserver$.next({ action: ModalActions.open, context, config });

        return this.getFilteredObservableByIdentifier(config.modalIdentifier);
    }

    /** This is how you access the modal. */
    getFilteredObservableByIdentifier(modalIdentifier: string): Observable<unknown> {
        return this.modalReturnedData$.pipe(
            filter((observedEvent: { modalIdentifier: string }) => observedEvent.modalIdentifier === modalIdentifier),
        );
    }

    /** Closes the modal
     * @param modalIdentifier Identifies the specific modal being closed */
    closeModal(modalIdentifier: string): void {
        return this.modalEventsObserver$.next({ action: ModalActions.close, modalIdentifier });
    }

    /** Tells the modal to update what state it is using
     * @param next_state State that the modal will be in when this method is called
     * @param modalIdentifier Identifies the specific modal being updated */
    updateModalState(next_state: string, modalIdentifier: unknown): void {
        return this.modalEventsObserver$.next({ action: ModalActions.updateState, next_state, modalIdentifier });
    }

    /** Provides the modal with a new context, without causing a possible rerender.
     * @param context The new data that the modal should use
     * @param modalIdentifier Identifies the specific modal being updated
     */
    updateModalContext(context: unknown, modalIdentifier: unknown): void {
        return this.modalEventsObserver$.next({ action: ModalActions.updateContext, data: context, modalIdentifier });
    }
}
