import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';

const { version } = require('../../../../package.json');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
        Sentry.init({
            dsn: 'https://414ce8367c8342a2a27c80ec20301b13@sentry.zipari.net/37',
            release: version,
            blacklistUrls: [/local\.zipari/],
            integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
        });
    }

    handleError(error: any) {
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}
