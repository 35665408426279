import { Pipe, PipeTransform } from '@angular/core';
import { isToday, parseISO, lightFormat } from 'date-fns';

@Pipe({
    name: 'timeDatePipe',
})
export class TimeDatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return isToday(parseISO(value)) ? lightFormat(new Date(value), 'hh:mm a') : lightFormat(new Date(value), 'MM/dd/yyyy');
    }
}
