import { Pipe, PipeTransform } from '@angular/core';
import { Name } from './../../api/models/name';

@Pipe({
    name: 'name-pipe',
})
export class NamePipe implements PipeTransform {
    transform(name: Name, ...args: any[]): string {
        if (!name) return '';
        const concatenatedName = `${name['prefix'] || ''} ${name['first_name'] || ''} ${name['middle_name'] || ''} ${
            name['last_name'] || ''
        } ${name['suffix'] || ''}`;

        return concatenatedName.replace(/\s+/g, ' ');
    }
}
