import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'snakeToTitlePipe',
})
export class SnakeToTitlePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (!value || typeof value !== 'string') {
            return '';
        }

        return value
            .split('_')
            .map(function (item) {
                return item.charAt(0).toUpperCase() + item.substring(1);
            })
            .join(' ');
    }
}
