/** The underlying object can be parsed as CSS. This cannot be `string` because of the way Angular handles CSS as an object. */
export type Css = object;

/** The underlying string can be parsed as JavaScript. */
export type JavaScript = string;

/** The underlying string can be parsed as HTML. */
export type Html = string;

/** The underlying string can be parsed as an integer. `parseInt(${numberString}) !== NaN` */
export type NumberString = string;

export function hash(stringToHash: string): number {
    let h = 0;
    for (let i = 0; i < stringToHash.length; i++) {
        const character = stringToHash.charCodeAt(i);
        const five = 5;
        // eslint-disable-next-line no-bitwise
        h = (h << five) - h + character;
        // eslint-disable-next-line no-bitwise
        h = h & h;
    }

    return h;
}
