import { Injectable } from '@angular/core';
import { hash } from '../utilities/String';

@Injectable({
    providedIn: 'root',
})
export class RequestTrackingService {
    readonly requestsInFlight = {};

    add(url: string, body: string = ''): void {
        this.requestsInFlight[this.generateUniqueHashcode(url, body)] = true;
    }

    contains(url: string, body: string = ''): boolean {
        return !!this.requestsInFlight[this.generateUniqueHashcode(url, body)];
    }

    remove(url: string, body: string = ''): void {
        delete this.requestsInFlight[this.generateUniqueHashcode(url, body)];
    }

    private generateUniqueHashcode(url: string, body: string): number {
        return hash(url + body || '');
    }
}
