import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dashedShortDate',
})
export class DashedDatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value || typeof value.indexOf !== 'function') {
            return '';
        }

        if (value.indexOf('T') >= 0) {
            value = value.slice(0, 10);
        }
        const date = new Date(`${value}T00:00:00`);

        return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    }
}
