import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CurrentPolicyService } from './services/current-policy.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { ProdGuard } from './guards/prod.guard';
import { CanActivateProductRouteGuard } from './guards/product-route.guard';
import { PipeService } from './pipe/pipe.service';
import { CxService } from './services/cx.service';
import { DataFilterService } from './services/data-filter.service';
import { DynamicRoutingService } from './services/dynamic-routing.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { LoggerService } from './services/logger.service';
import { SessionService } from './services/session.service';
import { ZipModal2Service } from './modules/zip-modal2/zip-modal2.service';

@NgModule({
    imports: [HttpClientModule],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                ConfigService,
                AuthService,
                CanActivateProductRouteGuard,
                GoogleAnalyticsService,
                SessionService,
                PipeService,
                DynamicRoutingService,
                DataFilterService,
                CxService,
                LoggerService,
                ProdGuard,
                CurrentPolicyService,
                ZipModal2Service,
            ],
        };
    }
}
