export const ROLES = {
    BROKER: 'Broker',
    MEMBER: 'Member',
    ANONYMOUS: 'Anonymous',
    CLIENT: 'Client',
    MANAGER: 'Manager',
    ADMIN: 'Admin',
    PROVIDER: 'Provider',
};

export const VALID_ROLES = ['Employer', 'Broker', 'Member', 'Anonymous', 'Client', 'Manager', 'Admin', 'Provider'];

export type VALID_ROLES_TYPE = 'Employer' | 'Broker' | 'Member' | 'Anonymous' | 'Client' | 'Manager' | 'Admin';
