import { Url } from '../../types/url.type';
import { ModalComponentTemplateTypes } from '../modal-templates/dynamic-modal-content.directive';

/** Enum that sets what actions a modal is allowed to emit
 * The CONFIRM event should be watched if a ZipForm is used, as this event will emit the data from that form. */
export enum ModalEventType {
    EXIT,
    CANCEL,
    CONFIRM,
}

/** Allowed modal states */
export const modalStates: ModalStates = {
    normalState: 'normalState',
    submittedState: 'submittedState',
    submittingState: 'submittingState',
    errorState: 'errorState',
};
export interface ModalStates {
    normalState: string;
    submittedState: string;
    submittingState: string;
    errorState: string;
}

/** Allowed modal actions */
export enum ModalActions {
    open,
    close,
    updateState,
    updateContext,
}

/** Types of modal content allowed in the body. String represents a simple string. HTML represents a string to be displayed using innerHtml.
 * Form expects a proper zip form config. Custom will assume that the string represents a component name to create and render. */
export const modalBodyType: ModalBodyType = {
    modalString: 'string',
    modalHtml: 'html',
    modalForm: 'form',
    modalCustom: 'custom',
};
export interface ModalBodyType {
    modalString: string;
    modalHtml: string;
    modalForm: string;
    modalCustom: string;
}

export type ModalBodyTypes = 'modalString' | 'modalHtml' | 'modalForm' | 'modalCustom';
/** Defines a state for a modal item */
export class ModalTextStateConfig {
    /** Text for the modal item */
    text: string;

    /** Value that determines if the string given for 'text' is a template or not. If true, the modal will attempt to do value.
     * Interpolation on the string using the modal's given context. */
    isTextATemplate?: boolean;

    /** Optional color param to change the text of the header text */
    color?: string;
}

/** Defines the state for a modal button. */
export class ModalButtonStateConfig {
    /** Text for the button in the current state */
    text: string;

    /** Color for button's current state */
    color?: string;

    /** Style for the button's current state */
    view?: string;

    /** Value that determines if the string given for 'text' is a template or not. If true, the modal will attempt to do value
     * interpolation on the string using the modal's given context. */
    isTextATemplate: boolean;

    /** A callback that will be called on click of the button *before* the event is emitted to the service */
    onClickAction?: Function;
}

/** Configuration for a modal header */
export class ModalHeaderConfig {
    /** Defines what icon to use for the exit symbol */
    exitIcon: string;

    /** Defines what the alt text will be for the exit icon */
    exitIconAltText: string;

    /** Defines the normal state for the modal header */
    normalState: ModalTextStateConfig;

    /** Defines the submitted state for the modal header */
    submittedState?: ModalTextStateConfig;

    image?: ModalHeaderImage;
}

export interface ModalHeaderImage {
    url: Url;
    altText: string; // Mandatory because a11y!
}

/** Defines state for a modal's main section */
export class ModalMainStateConfig {
    /** Content that will be displayed based on contentType set. This will be one of a string, zip form configuration json, or a component
     * name. */
    content: unknown;

    /** Content that will be displayed based on contentType set to html. It is available for html modal content type. */
    mobileContent?: unknown;

    /**
     * Type of content that will be displayed.
     * Only values within the modalBodyType type are allowed
     */
    contentType?: string;

    /**
     * Value that determines if the string given for 'text' is a template or not
     * If true, the modal will attempt to do value interpolation on the string using the modal's given context
     */
    isContentATemplate?: boolean;
}

/** Configuration for a modal body */
export class ModalMainConfig {
    /** Defines the normal state for the modal main section */
    normalState: ModalMainStateConfig;

    /** Defines the submitted state for the modal main section */
    submittedState?: ModalMainStateConfig;
}

/** Configuratino for modal footer */
export class ModalFooterConfig {
    /** Defines the states for the confirm button */
    confirmButton?: {
        /** Defines the normal state for the confirm button */
        normalState: ModalButtonStateConfig;

        /** Defines the submitted state for the confirm button */
        submittedState?: ModalButtonStateConfig;
    };

    /** Defines the states for the cancel button */
    cancelButton?: {
        /** Defines the normal state for the confirm button */
        normalState: ModalButtonStateConfig;

        /** Defines the submitted state for the confirm button */
        submittedState?: ModalButtonStateConfig;
    };
}

/**
 * Modal configuration interface
 *
 * A header, main, and footer section *must*be defined.
 *
 * Modal sections *must* have normalState defined,
 * but submittedState is optional - if needed, further states can be easily defined, but let's try to avoid doing that.
 *
 * Added states under consideration include....
 *
 * "successState", for when a modal has a state to show a
 * form is being submitted, and a successor state when the form is submitted successfully
 *
 * This setup allows simple configuration of multiple states for a modal, with each part of the modal being easy to define.
 *
 * An example use case would be a modal that shows a
 * form initially, but then simple html or plaintext that messages
 * successful submitting of the modal form to the user, or changes the button text from "submit" to "submitted".
 *
 * A class is not defined to implement this interface in order to
 * avoid binding up each part of a modal's config together, and to
 * keep pulling apart the ModalConfig interface/type as simple as possible.
 */
export interface Modal2Config {
    /**
     * Class that is added to modal sections (header, main, footer) to aid in custom styling of sections
     * E.g. could identify a particular modal then in tenant configs, override the styles to display the footer buttons with
     *      display: flex; justify-content: space-between;
     */
    modalIdentifier?: string;

    /**
     * When true, clicking confirm will close the modal automatically.
     *
     * Without this, the client that opened this modal *must* handle closing the modal itself.
     */
    closeOnConfirm?: boolean;

    /** Configuration for the modal body */
    header?: ModalHeaderConfig;

    /** Configuration for the modal body */
    main?: ModalMainConfig;

    /** Configuration for the modal footer */
    footer?: ModalFooterConfig;

    /** if dynamically rendering a component, use the following */

    /** Name of component being rendered*/
    useComponent?: ModalComponentTemplateTypes;

    /** Configs for that component
     *  This is defined by the component being rendered
     */
    configs?: unknown;
}
